@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 120px 0 0;
  padding: 0;
  font-family: arial, helvetica, sans-serif;
}

@media (max-width: 800px) {
  body {
    margin: 60px 0 0;
  }

  body.didomi-popup-open {
    position: unset !important;
  }
}

h1, h4, p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.section {
  margin: 100px 0 0;
  position: relative;
}

.section--no-mar {
  margin: 0;
}

.section-target {
  visibility: hidden;
  position: absolute;
  top: -135px;
}

@media (max-width: 800px) {
  .section-target--faq {
    top: 425px;
  }
}

.container {
  width: 1035px;
  margin: auto;
}

.container--small {
  width: 900px;
}

@media (max-width: 1100px) {
  .container {
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px;
  }
}

.premium-section-headline {
  color: #42474a;
  text-align: center;
  justify-content: center;
  font-size: 60px;
  font-weight: bold;
  display: flex;
}

.premium-logo-text {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: Poppins, helvetica, serif;
}

.premium-logo-text a {
  color: #fff;
}

.premium-logo-text a:hover {
  text-decoration: none;
}

.premium-block__item {
  flex: 0 0 33.3333%;
  margin: 50px 0 0;
}

footer {
  color: #fff;
  background: #1f1f23;
  padding: 56px 0;
  font-family: Poppins, helvetica, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  overflow: hidden;
}

footer > .container {
  max-width: 1032px;
}

.footer-version-switcher {
  display: none;
}

form.default-form:before, form.default-form:after {
  content: "";
  clear: both;
  display: table;
}

form.default-form input {
  box-sizing: border-box;
  max-width: 100%;
}

form.default-form input.input-text, form.default-form input input.input-big {
  color: #23262f;
  border: 1px solid;
  border-radius: 2px;
  outline: none;
  width: 200px;
  margin-top: 20px;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input[type="text"] {
  color: #23262f;
  border: 1px solid;
  border-radius: 2px;
  outline: none;
  width: 200px;
  margin-top: 20px;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input.input-text::placeholder {
  color: #000;
}

form.default-form input input.input-big::placeholder {
  color: #000;
}

form.default-form input[type="text"]::placeholder {
  color: #000;
}

form.default-form input.submit-button {
  border: 0;
  outline: none;
  width: 200px;
  margin-top: 20px;
  font-weight: bold;
  display: block;
}

form.default-form input.input-big {
  width: 370px;
  height: 50px;
  margin-right: 20px;
  padding: 12px;
  font-size: 20px;
}

form.default-form textarea {
  box-sizing: border-box;
  resize: vertical;
  color: #23262f;
  border: 1px solid;
  border-radius: 2px;
  outline: none;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  margin-top: 20px;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form .success-message {
  float: left;
  margin-left: 10px;
  display: none;
}

form.default-form .form-field {
  clear: both;
  margin-bottom: 20px;
}

form.default-form .form-field:before, form.default-form .form-field:after {
  content: "";
  clear: both;
  display: table;
}

form.default-form .form-field label {
  float: left;
  clear: both;
  margin-bottom: 11px;
  font-weight: bold;
  display: inline-block;
}

form.default-form .form-field input {
  clear: both;
  padding: 4.5px 5px;
  display: block;
}

form.default-form .form-field.field-faq {
  margin-top: 20px;
}

form.default-form .form-field.field-faq input, form.default-form .form-field.field-faq label {
  float: left;
  clear: none;
  vertical-align: middle;
}

form.default-form .form-field.field-faq input, form.default-form .form-field.field-faq textarea {
  width: 270px;
  margin-top: 0;
}

form.default-form .form-field.field-faq label {
  margin-bottom: 0;
  margin-right: 40px;
}

form.default-form .form-field.field-faq.form-faq-submit input {
  width: 145px;
}

form.default-form .form-field.search-field .input-big {
  clear: none;
  float: left;
  margin-top: 0;
  padding: 12px;
}

form.default-form .form-field.search-field .button-big {
  clear: none;
  float: right;
  padding: 15px 20px 10px;
}

form.default-form .form-field input[type="checkbox"] {
  display: none;
}

form.default-form .form-field input[type="checkbox"] + label {
  cursor: pointer;
  padding-left: 22px;
  font-weight: normal;
  position: relative;
}

form.default-form .form-field input[type="checkbox"] + label:before {
  content: "";
  border: 2px solid #2c2f3b;
  border-radius: 1px;
  width: 12px;
  height: 12px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: -1px;
  left: 0;
}

form.default-form .form-field input[type="checkbox"] + label:after {
  content: "✔";
  opacity: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 4.75px;
  left: 2.75px;
  transform: scale(0);
}

@media (max-width: 450px) {
  form.default-form .form-field input[type="checkbox"] + label {
    padding-left: 20px;
  }
}

form.default-form .form-field input[type="checkbox"]:checked + label:after {
  opacity: 1;
  top: 2.5px;
  left: 2px;
  transform: scale(1);
}

form.default-form .form-field input[type="radio"] {
  display: none;
}

form.default-form .form-field input[type="radio"] + label {
  cursor: pointer;
  padding-left: 25px;
  font-weight: normal;
  position: relative;
}

form.default-form .form-field input[type="radio"] + label:before {
  content: "";
  border: 2px solid #2c2f3b;
  border-radius: 18px;
  width: 13px;
  height: 13px;
  transition: all .3s;
  position: absolute;
  top: -1px;
  left: 0;
}

form.default-form .form-field input[type="radio"] + label:after {
  content: "";
  color: #2c2f3b;
  background: #2c2f3b;
  border-radius: 350px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 8.25px;
  left: 8.7px;
}

form.default-form .form-field input[type="radio"]:checked + label:after {
  width: 9px;
  height: 9px;
  top: 2.75px;
  left: 4.2px;
}

form.default-form .form-field .error-message {
  float: left;
  margin-left: 10px;
  display: none;
}

form.default-form .form-field.with-error .error-message {
  display: block;
}

form.default-form .form-field.with-error input, form.default-form .form-field.with-error textarea {
  border: 1px solid;
}

form.default-form .form-field .counter {
  margin: 5px 0;
}

.calendar-field input {
  background-image: url("/images/reflex2016/source/svg/calendar.svg");
  background-position: right 5px center;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  padding-right: 35px;
}

.magazine-subscription h3 {
  margin: 40px 0 10px;
}

.magazine-subscription .required-fields-info {
  margin-bottom: 20px;
}

.magazine-subscription .first-mag-subscription-row {
  margin-top: -20px;
}

.magazine-subscription .button-row {
  padding: 30px 0 50px;
}

.magazine-subscription .button-row .main-button {
  margin: 0 auto;
}

.magazine-subscription .magazine-subscription-row {
  border-bottom: 1px solid;
}

.magazine-subscription .magazine-subscription-row:before, .magazine-subscription .magazine-subscription-row:after {
  content: "";
  clear: both;
  display: table;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full {
  margin-bottom: 40px;
  padding-left: 20px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left, .magazine-subscription .magazine-subscription-row .magazine-subscription-right {
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 40px;
  padding-left: 20px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left {
  float: left;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-right {
  float: right;
}

.magazine-subscription .magazine-subscription-row .required label {
  font-weight: bold;
}

.magazine-subscription .magazine-subscription-row .required label:after {
  content: "*";
}

.magazine-subscription .subscription-address-options .subscription-field {
  margin-top: 0;
  margin-bottom: 15px;
}

.magazine-subscription .subscription-address-options .subscription-field label {
  text-align: right;
  width: 110px;
  display: inline-block;
}

.magazine-subscription .subscription-address-options .subscription-field label.hidden {
  display: none;
}

.magazine-subscription .subscription-address-options .subscription-field input {
  width: 215px;
  margin-top: 0;
  margin-left: 5px;
  display: inline-block;
}

.magazine-subscription .subscription-address-options .subscription-field input.with-error {
  border: 1px solid;
}

.magazine-subscription .subscription-address-options .address-numbers input {
  width: 100px;
}

.magazine-subscription .subscription-address-options .address-numbers .address-slash {
  vertical-align: middle;
  text-align: center;
  width: 2px;
  display: inline-block;
}

.magazine-subscription .subscription-address-options .address-numbers .margin-address-number {
  margin-left: 5px;
}

.magazine-subscription .subscription-select-options {
  margin-top: 25px;
}

.magazine-subscription .subscription-select-options .subscription-select-row {
  margin-bottom: 8px;
  position: relative;
}

.magazine-subscription .subscription-select-options .subscription-select-row label {
  font-weight: normal;
}

.magazine-subscription .subscription-select-options .subscription-select-row .calendar-field {
  margin-top: 7px;
}

.magazine-subscription .subscription-select-options .subscription-select-row.normal-weight {
  margin-bottom: 20px;
}

.magazine-subscription .subscription-select-options .subscription-select-row.normal-weight label {
  font-weight: normal;
}

.magazine-subscription .subscription-select-options .subscription-select-row .sipo-number-box {
  display: none;
  position: absolute;
  bottom: -8.3px;
  right: 135px;
}

.magazine-subscription .subscription-select-options .subscription-select-row .sipo-number-box input.sipo-number-field, .magazine-subscription .subscription-select-options .subscription-select-row .sipo-number-box label.sipo-number-field-label {
  display: inline;
}

.magazine-subscription .subscription-select-options .subscription-select-row .sipo-number-box label.sipo-number-field-label {
  padding-right: 10px;
  font-weight: bold;
}

.magazine-subscription .subscription-select-options .subscription-select-row .sipo-number-box input.sipo-number-field {
  width: 215px;
}

.magazine-subscription .subscription-select-options input[type="radio"] {
  display: none;
}

.magazine-subscription .subscription-select-options input[type="radio"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: bold;
  position: relative;
}

.magazine-subscription .subscription-select-options input[type="radio"] + label:before {
  content: "";
  border: 2px solid;
  border-radius: 18px;
  width: 13px;
  height: 13px;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.magazine-subscription .subscription-select-options input[type="radio"] + label:after {
  content: "";
  border-radius: 350px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 8.25px;
  left: 8.7px;
}

.magazine-subscription .subscription-select-options input[type="radio"]:checked + label:after {
  width: 9px;
  height: 9px;
  top: 3.75px;
  left: 4.2px;
}

.magazine-subscription input[type="checkbox"] {
  display: none;
}

.magazine-subscription input[type="checkbox"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: normal;
  position: relative;
}

.magazine-subscription input[type="checkbox"] + label:before {
  content: "";
  border: 2px solid;
  border-radius: 1px;
  width: 12px;
  height: 12px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 1px;
  left: 0;
}

.magazine-subscription input[type="checkbox"] + label:after {
  content: "✔";
  opacity: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 4.75px;
  left: 2.75px;
  transform: scale(0);
}

.magazine-subscription input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

select.electionsSpecial {
  color: #9295a4;
  -webkit-appearance: none;
  appearance: none;
  background: url("/images/reflex2016/source/svg/arrow-down.svg") 100% / 30px 10px no-repeat;
  border: 1px solid;
  border-radius: 2px;
  outline: none;
  width: 100%;
  height: 33px;
  margin-top: 20px;
  padding: 5px;
  font-size: 14px;
  transition: all .3s ease-in-out;
  display: block;
}

textarea {
  max-width: 100%;
}

.premium-button {
  color: #fff;
  background-color: red;
  border-radius: 2px;
  padding: 15px 65px;
  font-family: Poppins, helvetica, serif;
  font-size: 20px;
  display: table;
}

@media (max-width: 800px) {
  .premium-button {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .premium-button {
    padding: 15px 20px;
    font-size: 18px;
  }
}

.user-bonuses {
  line-height: 1.2;
}

.user-bonuses *, .user-bonuses :before, .user-bonuses :after {
  box-sizing: border-box;
}

.user-bonuses__group {
  margin-bottom: 30px;
}

.user-bonuses__group:last-child {
  margin-bottom: 0;
}

.user-bonuses__main-title {
  margin: 0 0 15px;
  font-weight: 700;
}

.user-bonuses__type-title {
  margin-bottom: 5px !important;
}

.user-bonuses__list {
  border: 1px solid #dcdcdc;
  margin: 0;
  list-style: none;
  padding: 15px 30px 15px 15px !important;
}

.user-bonuses__item {
  margin-bottom: 35px;
}

.user-bonuses__item:last-child {
  margin-bottom: 0;
}

.user-bonus {
  align-items: flex-start;
  display: flex;
}

.user-bonus__image-wrapper {
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 65px;
  display: flex;
  overflow: hidden;
}

.user-bonus__image {
  object-fit: contain;
  flex-basis: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.user-bonus__body {
  flex-basis: calc(100% - 75px);
  align-items: flex-start;
  padding-left: 20px;
  display: flex;
}

.user-bonus__info {
  width: 100%;
  padding: 0 30px 0 0;
  line-height: 1.3;
}

.user-bonus__name {
  font-weight: 700;
}

.user-bonus__link {
  color: red;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  display: inline;
}

.user-bonus__link:hover {
  text-decoration: none;
}

.user-bonus__code-wrapper {
  border: 1px solid red;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px 35px;
  position: relative;
}

.user-bonus__code-wrapper:hover, .user-bonus__code-wrapper:focus {
  border-color: #e60000;
}

.user-bonus__code-wrapper--has-code:before {
  content: "Kód byl zkopírován";
  z-index: 1;
  color: #fff;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  background: #65c61e;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .5s ease-in-out;
  display: flex;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.user-bonus__code-wrapper--copied {
  border-color: #65c61e;
  transition: border-color .5s ease-in-out;
}

.user-bonus__code-wrapper--copied:before {
  opacity: 1;
}

.user-bonus__codeurl-wrapper {
  min-width: 150px;
  max-width: 150px;
  height: 30px;
  margin: auto 0 auto auto;
  position: relative;
}

.user-bonus__codeurl-wrapper .user-bonus__codeurl {
  text-align: center;
  border: 1px solid red;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px;
  display: block;
  overflow: hidden;
}

.user-bonus__get-code-button {
  z-index: 1;
  text-align: left;
  color: #fff;
  border-radius: inherit;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .8);
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.user-bonus__get-code-button:hover:before, .user-bonus__get-code-button:focus:before {
  background-color: #e60000;
  width: 80px;
}

.user-bonus__get-code-button:hover:after, .user-bonus__get-code-button:focus:after {
  border-bottom-color: #e60000;
  border-right-width: 35px;
  left: 80px;
}

.user-bonus__get-code-button:before, .user-bonus__get-code-button:after {
  content: "";
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
}

.user-bonus__get-code-button:before {
  background-color: red;
  width: 86px;
  height: 100%;
  left: 0;
}

.user-bonus__get-code-button:after {
  border-top: none;
  border-bottom: 28px solid red;
  border-left: none;
  border-right: 30px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  left: 86px;
}

.user-bonus__code-wrapper--has-code .user-bonus__get-code-button {
  display: none;
}

.user-bonus__get-code-button-text {
  z-index: 2;
  position: relative;
}

.user-bonus__cut-code-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.user-bonus__code {
  text-align: center;
  pointer-events: none;
  border: none;
  max-width: 100%;
  padding: 0;
}

.user-bonus__code-wrapper--has-code .user-bonus__code {
  pointer-events: auto;
}

.user-bonus__address {
  text-align: right;
  margin-left: auto;
  line-height: 20px;
}

.user-bonus__address-title {
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 3px !important;
}

.user-bonus__code-valid-to-wrapper {
  z-index: 1;
  text-align: center;
  color: #b0b0b0;
  width: 100%;
  padding: 4px 0 0;
  font-size: 10px;
  line-height: 1.1;
  position: absolute;
  top: 100%;
  left: 0;
}

.user-bonus__no-code-error {
  text-align: center;
  color: red;
  align-self: center;
  font-weight: 700;
}

.user-bonus__no-code-error--hidden {
  display: none;
}

.user-bonus-popup-overlay {
  z-index: 1252;
  background-color: rgba(0, 0, 0, .2);
  align-items: flex-start;
  max-height: 100vh;
  padding: 50px 0;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.user-bonus-popup-overlay--hidden {
  display: none;
}

.user-bonus-popup {
  z-index: 1253;
  background-color: #fff;
  border-radius: 3px;
  width: 470px;
  margin: auto;
  padding: 30px 30px 30px 40px;
  font-size: 13px;
  line-height: 1.3;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.user-bonus-popup__close-button {
  cursor: pointer;
  background: #fff url("https://img2.cncenter.cz/images/default/user-bonus/close-btn.svg") center / 16px no-repeat;
  border: none;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 0;
  display: block;
  position: absolute;
  top: -7px;
  right: -8px;
}

.user-bonus-popup__header {
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.user-bonus-popup__image-wrapper {
  width: 160px;
  height: 80px;
}

.user-bonus-popup__info {
  flex-basis: calc(100% - 160px);
  padding-left: 30px;
}

.user-bonus-popup__code-section {
  text-align: center;
  margin-bottom: 30px;
}

.user-bonus-popup__code-section--hidden {
  display: none;
}

.user-bonus-popup__code-wrapper {
  width: 220px;
  max-width: none;
  margin: 4px auto;
}

.user-bonus-popup__cut-code-button {
  background-size: contain;
  left: 13px;
}

.user-bonus-popup__code {
  text-align: center;
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.user-bonus-popup__code-valid-to-wrapper {
  text-align: center;
  display: block;
  position: static;
}

.user-bonus-popup__no-code-error {
  margin-bottom: 30px !important;
}

.user-bonus-popup__requirements {
  overflow: auto;
}

.user-bonus-popup__requirements p {
  margin-bottom: 1em !important;
}

@media (max-width: 600px) and (max-width: 600px) {
  .user-bonuses__list {
    padding-right: 15px !important;
  }
}

@media (max-width: 600px) {
  .user-bonus {
    align-items: flex-start;
  }
}

@media (max-width: 600px) and (max-width: 600px) {
  .user-bonus__body {
    display: block;
  }

  .user-bonus__info {
    margin-bottom: 15px;
    padding: 0;
  }

  .user-bonus__info:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 600px) {
  .user-bonus__info p {
    margin: 0;
  }
}

@media (max-width: 600px) and (max-width: 600px) {
  .user-bonus__code-wrapper, .user-bonus__codeurl-wrapper {
    max-width: 135px;
    margin-left: 0;
  }

  .user-bonus__address {
    text-align: left;
  }
}

@media (max-width: 600px) {
  .user-bonus__no-code-error {
    text-align: left;
  }

  .user-bonus-popup {
    padding-top: 25px;
  }

  .user-bonus-popup__close-button {
    right: 0;
  }

  .user-bonus-popup__header {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .user-bonus-popup__info {
    flex-basis: auto;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .user-bonus-popup__image-wrapper {
    flex-basis: auto;
    width: 160px;
    margin: 0 auto 10px;
  }

  .user-bonus-popup__code-wrapper {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.user-bonuses {
  margin: 0;
  display: block;
}

.user-bonus__cut-code-button {
  background: url("https://img2.cncenter.cz/images/default/user-bonus/scissors.svg") 50% no-repeat;
}

.user-bonus__code-wrapper {
  height: 30px;
  font-size: 13px;
  line-height: 15px;
}

.user-bonus__get-code-button-text {
  font-size: 13px;
  line-height: 15px;
}

.user-bonus__code {
  height: 15px;
  font-size: 13px;
  line-height: 15px;
}

.user-bonus__link {
  font-size: 13px;
}

.premium-menu {
  z-index: 5;
  background-color: #1f1e26;
  width: 100%;
  height: 72px;
  transition: all .3s;
  position: fixed;
  top: 0;
}

@media (max-width: 950px) {
  .premium-menu {
    height: 48px;
  }
}

.premium-menu__wrap {
  justify-content: space-between;
  align-items: center;
  width: 1080px;
  height: 100%;
  margin: auto;
  display: flex;
  position: relative;
}

@media (max-width: 1100px) {
  .premium-menu__wrap {
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
  }
}

.premium-menu__wrap.menu-open .premium-menu__bottom {
  max-height: 400px;
}

.premium-menu__wrap.menu-open .menu-control-line--2 {
  display: none;
}

.premium-menu__wrap.menu-open .menu-control-line--1 {
  top: 3px;
  right: -1px;
  transform: rotate(45deg);
}

.premium-menu__wrap.menu-open .menu-control-line--3 {
  transform: rotate(-45deg);
}

@media (max-width: 950px) {
  .premium-menu__wrap.menu-open .premium-menu__box ul {
    background-color: #1f1e26;
    width: 100%;
    padding: 0;
    display: block;
    position: absolute;
    top: 48px;
    left: 0;
  }

  .premium-menu__wrap.menu-open .premium-menu__box ul li {
    margin: 0 0 18px 15px;
    display: block;
  }
}

@media (max-width: 750px) {
  .premium-menu__wrap.menu-open .premium-menu__box ul {
    padding-top: 35px;
  }
}

.premium-menu__left {
  align-items: center;
  gap: 40px;
  display: flex;
}

.premium-menu__right {
  align-items: center;
  gap: 32px;
  display: flex;
}

@media (max-width: 950px) {
  .premium-menu__right {
    gap: 8px;
  }
}

.premium-menu__logo {
  opacity: 0;
  width: 176px;
  height: 24px;
  transition: all .3s;
  display: block;
}

@media (max-width: 950px) {
  .premium-menu__logo {
    width: 111px;
    height: 14px;
    margin-left: 0;
  }
}

.premium-menu__logo a {
  text-decoration: none;
}

.premium-menu__button {
  opacity: 0;
  transition: all .3s;
  display: none;
}

@media (max-width: 750px) {
  .premium-menu__button {
    margin-right: 0;
    padding-top: 0;
  }
}

.premium-menu__button a {
  color: #fff;
  background-color: #f66533;
  padding: 6px 20px;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  display: block;
}

.premium-menu__button a:hover {
  opacity: .8;
}

.premium-menu__button a span {
  font-family: Poppins, helvetica, serif;
}

@media (max-width: 750px) {
  .premium-menu__button a {
    color: #f66533;
    background: none;
    padding: 0;
    text-decoration: underline;
  }
}

.premium-menu__login {
  color: #f2eae4;
  font-family: Poppins, helvetica, serif;
}

.premium-menu__login span {
  color: #f2eae4;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  transition: all .3s;
  position: relative;
}

@media (max-width: 950px) {
  .premium-menu__login span {
    display: none;
  }
}

.premium-menu__login span:after {
  content: "";
  opacity: 0;
  background-color: #f66533;
  border-radius: 4px;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.premium-menu__login span:hover:after {
  opacity: 1;
}

@media (max-width: 400px) {
  .premium-menu__login {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;
  }
}

.premium-menu__box ul {
  color: #f2eae4;
  gap: 32px;
  margin: 0 auto;
  padding: 0;
  font-size: 14px;
  display: flex;
}

@media (max-width: 1000px) {
  .premium-menu__box ul {
    display: none;
  }
}

.premium-menu__box ul li {
  list-style-type: none;
  display: inline-block;
}

.premium-menu__box ul li:last-child {
  margin: 0;
}

.premium-menu__box ul li a {
  position: relative;
}

.premium-menu__box ul li a:after {
  content: "";
  opacity: 0;
  background-color: #f66533;
  border-radius: 4px;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.premium-menu__box ul li a:hover:after {
  opacity: 1;
}

.premium-menu__registration {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 15px;
}

.premium-menu__registration a {
  color: #f2eae4;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  position: relative;
}

.premium-menu__registration a:after {
  content: "";
  opacity: 0;
  background-color: #f66533;
  border-radius: 4px;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.premium-menu__registration a:hover:after {
  opacity: 1;
}

.premium-menu__link {
  color: #f2eae4;
  font-family: Poppins, helvetica, serif;
}

.premium-menu.logo_show .premium-menu__logo {
  opacity: 1;
}

@media (max-width: 750px) {
  .premium-menu.button_show .premium-menu__registration {
    display: none;
  }
}

.premium-menu.button_show .premium-menu__button {
  opacity: 1;
  display: block;
}

.premium-menu.button_show .premium-menu__button:hover {
  opacity: .8;
}

.premium-menu__hamburger {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: none;
  position: relative;
}

@media (max-width: 950px) {
  .premium-menu__hamburger {
    display: block;
  }
}

.premium-menu .menu-control-line {
  transform-origin: 0 0;
  background-color: #fff;
  border-radius: 2px;
  width: 16px;
  height: 2px;
  transition: all .3s ease-in-out;
  position: absolute;
}

.premium-menu .menu-control-line--1 {
  top: 0;
}

.premium-menu .menu-control-line--2 {
  top: 50%;
  transform: translateY(-50%);
}

.premium-menu .menu-control-line--3 {
  bottom: 0;
}

.premium-menu.inner-page {
  background-color: #f7f7f7;
  box-shadow: 0 4px 36px rgba(0, 0, 0, .12);
}

@media (max-width: 750px) {
  .premium-menu.inner-page .menu-open .premium-menu__registration {
    display: none;
  }
}

.premium-menu.inner-page .premium-menu__box ul {
  background-color: #f7f7f7;
}

.premium-menu.inner-page .premium-menu__box ul, .premium-menu.inner-page .premium-menu__link, .premium-menu.inner-page .premium-menu__login span, .premium-menu.inner-page .premium-menu__registration a, .premium-menu.inner-page .premium-menu__login {
  color: #1f1e26;
}

.premium-menu.inner-page .premium-menu__link.active {
  text-decoration: underline;
}

.premium-menu.inner-page .premium-menu__login {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.premium-menu.inner-page .premium-menu__login .premium-menu__icon {
  height: 24px;
}

.premium-menu.inner-page .premium-logo-black {
  fill: #1f1e26 !important;
}

.premium-menu.inner-page .premium-menu__logo, .premium-menu.inner-page .premium-menu__button {
  opacity: 1;
}

.premium-menu.inner-page .menu-control-line {
  background-color: #1f1e26;
}

.premium-menu.button-order-hide .premium-menu__button {
  display: none;
}

.premium-menu.hp-white-page {
  background-color: #f7f7f7;
  box-shadow: 0 4px 36px rgba(0, 0, 0, .12);
}

.premium-menu.hp-white-page .premium-menu__box ul {
  background-color: #f7f7f7;
}

.premium-menu.hp-white-page .premium-menu__box ul, .premium-menu.hp-white-page .premium-menu__link, .premium-menu.hp-white-page .premium-menu__login span, .premium-menu.hp-white-page .premium-menu__registration a, .premium-menu.hp-white-page .premium-menu__login {
  color: #1f1e26;
}

.premium-menu.hp-white-page .premium-menu__link.active {
  text-decoration: underline;
}

.premium-menu.hp-white-page .premium-menu__login {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.premium-menu.hp-white-page .premium-menu__login .premium-menu__icon {
  height: 24px;
  display: none;
}

@media (max-width: 950px) {
  .premium-menu.hp-white-page .premium-menu__login .premium-menu__icon {
    display: block;
  }
}

.premium-menu.hp-white-page .premium-menu__logo {
  opacity: 1;
}

.premium-menu.hp-white-page .premium-logo-black {
  fill: #1f1e26 !important;
}

.premium-menu.hp-white-page .menu-control-line {
  background-color: #1f1e26;
}

.premium-promobox {
  background-color: #1f1e26;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  padding: 176px 10px 104px;
  display: flex;
}

@media (max-width: 555px) {
  .premium-promobox {
    padding: 88px 10px 40px;
  }
}

.premium-promobox--noheader {
  padding: 104px 10px;
}

@media (max-width: 555px) {
  .premium-promobox--noheader {
    padding: 40px 16px;
  }
}

.premium-promobox__info {
  text-align: center;
  color: #f2eae4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  width: 100%;
  display: flex;
}

@media (max-width: 555px) {
  .premium-promobox__info {
    gap: 40px;
  }
}

.premium-promobox__titles {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

.premium-promobox__titles .premium-promobox__title {
  font-family: Poppins, helvetica, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}

@media (max-width: 555px) {
  .premium-promobox__titles .premium-promobox__title {
    font-size: 28px;
    line-height: 40px;
  }
}

.premium-promobox__titles .premium-promobox__subtitle {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 555px) {
  .premium-promobox__titles .premium-promobox__subtitle {
    font-size: 16px;
    line-height: 28px;
  }
}

.premium-promobox__text {
  max-width: 550px;
  margin: 15px auto 0;
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  line-height: 1.4;
}

@media (max-width: 555px) {
  .premium-promobox__text {
    font-size: 20px;
  }
}

.premium-promobox__login {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.premium-promobox__login-title {
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 555px) {
  .premium-promobox__login-title {
    font-size: 26px;
    line-height: 32px;
  }
}

.premium-promobox__login-link {
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-decoration-line: underline;
}

@media (max-width: 555px) {
  .premium-promobox__login-link {
    font-size: 18px;
    line-height: 28px;
  }
}

.premium-promobox__wrapper {
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  width: 100%;
  display: flex;
}

.premium-promobox__offer {
  background: #fef4f0;
  border-radius: 15px;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 448px;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  padding: 40px;
  display: flex;
}

@media (max-width: 555px) {
  .premium-promobox__offer {
    flex-shrink: 1;
    gap: 24px;
    padding: 24px 16px;
  }
}

.premium-promobox__offer-top {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
}

.premium-promobox__offer-top .premium-promobox__logo {
  background: rgba(255, 255, 255, .25);
  border: 1px solid #fbc1ad;
  border-radius: 100px;
  align-items: center;
  gap: 4px;
  height: 24px;
  padding: 8px 16px;
  display: flex;
}

.premium-promobox__offer-top .premium-promobox__logo--black {
  background-color: #000;
  border-color: #000;
  gap: 8px;
}

.premium-promobox__offer-top .premium-promobox__logo--black .divider {
  background-color: #e7e7e7;
  width: 1px;
  height: 12px;
}

.premium-promobox__offer-top .premium-promobox__prices {
  flex-wrap: wrap;
  align-items: baseline;
  gap: 8px;
  display: flex;
}

@media (max-width: 555px) {
  .premium-promobox__offer-top .premium-promobox__prices {
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
  }
}

.premium-promobox__offer-top .premium-promobox__prices .premium-promobox__discount {
  color: #1f1f23;
  font-family: Poppins, helvetica, serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.premium-promobox__offer-top .premium-promobox__prices .premium-promobox__period {
  color: #70707b;
  font-family: Roboto, helvetica, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.premium-promobox__offer-top .premium-promobox__prices .premium-promobox__discount-note {
  color: #1f1f23;
  font-family: Roboto, helvetica, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.premium-promobox__offer-top .premium-promobox__prices .premium-promobox__fullprice {
  color: #1f1f23;
  font-family: Roboto, helvetica, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.premium-promobox__offer-top .premium-promobox__prices .premium-promobox__fullprice:before {
  content: "/";
  display: inline;
}

@media (max-width: 555px) {
  .premium-promobox__offer-top .premium-promobox__prices .premium-promobox__fullprice:before {
    display: none;
  }
}

.premium-promobox__offer-advantages {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.premium-promobox__offer-advantages ul {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 0;
  padding: 0;
  display: flex;
}

.premium-promobox__offer-advantages ul li {
  color: #1f1f23;
  text-align: left;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  display: flex;
}

.premium-promobox__offer-advantages ul li:before {
  content: "";
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M12%202C6.48%202%202%206.48%202%2012C2%2017.52%206.48%2022%2012%2022C17.52%2022%2022%2017.52%2022%2012C22%206.48%2017.52%202%2012%202ZM12%2020C7.59%2020%204%2016.41%204%2012C4%207.59%207.59%204%2012%204C16.41%204%2020%207.59%2020%2012C20%2016.41%2016.41%2020%2012%2020ZM15.88%208.29L10%2014.17L8.12%2012.29C7.73%2011.9%207.1%2011.9%206.71%2012.29C6.32%2012.68%206.32%2013.31%206.71%2013.7L9.3%2016.29C9.69%2016.68%2010.32%2016.68%2010.71%2016.29L17.3%209.7C17.69%209.31%2017.69%208.68%2017.3%208.29C16.91%207.9%2016.27%207.9%2015.88%208.29Z%22%20fill%3D%22%23F66533%22%2F%3E%3C%2Fsvg%3E");
  width: 24px;
  height: 24px;
  display: inline-block;
}

.premium-promobox__offer-advantages ul li.disadvantage:before {
  content: "";
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M13.89%208.7L12%2010.59L10.11%208.7C9.72%208.31%209.09%208.31%208.7%208.7C8.31%209.09%208.31%209.72%208.7%2010.11L10.59%2012L8.7%2013.89C8.31%2014.28%208.31%2014.91%208.7%2015.3C9.09%2015.69%209.72%2015.69%2010.11%2015.3L12%2013.41L13.89%2015.3C14.28%2015.69%2014.91%2015.69%2015.3%2015.3C15.69%2014.91%2015.69%2014.28%2015.3%2013.89L13.41%2012L15.3%2010.11C15.69%209.72%2015.69%209.09%2015.3%208.7C14.91%208.32%2014.27%208.32%2013.89%208.7ZM12%202C6.47%202%202%206.47%202%2012C2%2017.53%206.47%2022%2012%2022C17.53%2022%2022%2017.53%2022%2012C22%206.47%2017.53%202%2012%202ZM12%2020C7.59%2020%204%2016.41%204%2012C4%207.59%207.59%204%2012%204C16.41%204%2020%207.59%2020%2012C20%2016.41%2016.41%2020%2012%2020Z%22%20fill%3D%22%2370707B%22%2F%3E%3C%2Fsvg%3E");
  width: 24px;
  height: 24px;
  display: inline-block;
}

.premium-promobox__offer-advantages ul li a {
  text-decoration: underline;
}

.premium-promobox__offer-advantages .premium-promobox__price-advantage {
  color: #70707b;
  font-family: Roboto, helvetica, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.premium-promobox__offer .premium-promobox__note {
  color: #1f1f23;
  text-align: left;
  font-family: Roboto, helvetica, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.premium-promobox__offer .premium-promobox__button {
  background: #f66533;
  border-radius: 2px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.premium-promobox__offer .premium-promobox__button:hover {
  opacity: .8;
}

.premium-promobox__offer--left {
  flex-basis: 416px;
  justify-content: space-between;
  gap: 32px;
}

.premium-promobox__offer--left .premium-promobox__offer-top {
  align-items: flex-start;
  gap: 32px;
}

.premium-promobox__explanation {
  color: #1f1e26;
  margin-top: 5px;
  font-size: 12px;
}

.premium-promobox__button {
  background: #f66533;
  padding: 17px 105px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.premium-promobox__button:hover {
  opacity: .8;
}

.premium-magazines--wrapper {
  background-color: #fff;
  padding: 152px 0;
}

@media (max-width: 900px) {
  .premium-magazines--wrapper {
    padding: 56px 0;
  }
}

.premium-magazines {
  background-color: #fff;
  align-items: center;
  gap: 80px;
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 1100px) {
  .premium-magazines {
    flex-direction: column;
    gap: 24px;
    width: calc(100% - 32px);
  }
}

.premium-magazines__advantages {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 40px;
  display: flex;
}

@media (max-width: 550px) {
  .premium-magazines__advantages {
    gap: 32px;
  }
}

.premium-magazines__advantages .premium-magazines__logo {
  align-items: center;
  gap: 10px;
  height: 24px;
  display: flex;
}

.premium-magazines__advantages .premium-magazines__title {
  color: #1f1f23;
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 550px) {
  .premium-magazines__advantages .premium-magazines__title {
    font-size: 26px;
    line-height: 32px;
  }
}

.premium-magazines__advantages .premium-magazines__subtitle {
  color: #1f1f23;
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.premium-magazines__advantages .premium-magazines__details {
  align-items: center;
  gap: 10px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.premium-magazines__advantages .premium-magazines__details:hover {
  text-decoration: underline;
}

.premium-magazines__magazines {
  flex-wrap: wrap;
  flex-shrink: 0;
  place-content: flex-start flex-end;
  align-items: flex-start;
  gap: 24px;
  width: 504px;
  height: 216px;
  display: flex;
}

@media (max-width: 550px) {
  .premium-magazines__magazines {
    justify-content: center;
    width: 100%;
    height: auto;
  }
}

.premium-magazines__magazines-logo {
  border: 1px solid #e7e7e7;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 126px;
  padding: 16px 12px;
  display: flex;
}

.premium-magazines__magazines-logo img {
  height: 24px;
}

.premium-noads {
  color: #1f1f23;
  text-align: center;
  background: #f4f4f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin: 0 auto;
  padding-top: 152px;
  display: flex;
}

@media (max-width: 900px) {
  .premium-noads {
    width: calc(100% - 32px);
    padding-top: 56px;
  }
}

.premium-noads__titles {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.premium-noads__title {
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 900px) {
  .premium-noads__title {
    font-size: 26px;
    line-height: 32px;
  }
}

.premium-noads__subtitle {
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.premium-noads__banner {
  width: calc(100% - 32px);
  max-width: 1032px;
}

.premium-editions--wrapper {
  background-color: #fff;
  background-image: url("/images/premium/dist/non-svg/themes-bg.png");
  background-position: 100%;
  background-repeat: no-repeat;
  padding: 104px 0 64px;
}

@media (max-width: 900px) {
  .premium-editions--wrapper {
    padding: 64px 0 0;
  }
}

.premium-editions {
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  max-width: 1032px;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 900px) {
  .premium-editions {
    flex-direction: column;
    gap: 24px;
  }
}

.premium-editions__versions {
  color: #1f1f23;
  flex-direction: column;
  flex-basis: 448px;
  align-items: flex-start;
  gap: 40px;
  display: flex;
}

@media (max-width: 900px) {
  .premium-editions__versions {
    flex-basis: auto;
    gap: 32px;
  }
}

.premium-editions__titles {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.premium-editions__title {
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 900px) {
  .premium-editions__title {
    font-size: 26px;
    line-height: 32px;
  }
}

.premium-editions__subtitle {
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.premium-editions__link {
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.premium-editions__link:hover {
  text-decoration: underline;
}

.premium-themes--wrapper {
  background-color: #1f1f23;
  padding: 152px 0 0;
}

@media (max-width: 900px) {
  .premium-themes--wrapper {
    padding: 64px 16px 0;
  }
}

.premium-themes {
  color: #fff;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 204px);
  padding-left: 204px;
  display: flex;
  overflow: hidden;
}

@media (max-width: 900px) {
  .premium-themes {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 0;
  }
}

.premium-themes__theme {
  flex-direction: column;
  flex-basis: 448px;
  align-items: flex-start;
  gap: 40px;
  display: flex;
}

@media (max-width: 900px) {
  .premium-themes__theme {
    flex-basis: content;
  }
}

.premium-themes__titles {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

.premium-themes__title {
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 900px) {
  .premium-themes__title {
    font-size: 26px;
    line-height: 32px;
  }
}

.premium-themes__subtitle {
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.premium-themes__link {
  align-items: center;
  gap: 10px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.premium-themes__link:hover {
  text-decoration: underline;
}

.premium-themes__banner--desktop {
  display: block;
}

@media (max-width: 900px) {
  .premium-themes__banner--desktop {
    display: none;
  }
}

.premium-themes__banner--mobile {
  display: none;
}

@media (max-width: 900px) {
  .premium-themes__banner--mobile {
    display: block;
  }
}

.premium-connected--wrapper {
  background-color: #fafafa;
  padding: 152px 0 0 204px;
  overflow: hidden;
}

@media (max-width: 900px) {
  .premium-connected--wrapper {
    padding: 64px 16px 0;
  }
}

.premium-connected {
  color: #1f1f23;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

@media (max-width: 900px) {
  .premium-connected {
    flex-direction: column;
  }
}

.premium-connected__device {
  flex-direction: column;
  flex-basis: 448px;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

@media (max-width: 900px) {
  .premium-connected__device {
    flex-basis: auto;
    align-items: center;
    width: 100%;
  }
}

.premium-connected__title {
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 900px) {
  .premium-connected__title {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
  }
}

.premium-connected__subtitle {
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-style: normal;
  line-height: 28px;
}

.premium-connected__advantages {
  gap: 16px;
  display: flex;
}

@media (max-width: 900px) {
  .premium-connected__advantages {
    width: 100%;
  }
}

.premium-connected__advantage {
  background-position: center;
  width: 204px;
  height: 329px;
  padding: 24px;
  font-family: Roboto, helvetica, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

@media (max-width: 900px) {
  .premium-connected__advantage {
    width: 33%;
  }
}

.premium-connected__advantage--computer {
  background: url("/images/premium/dist/non-svg/advantage-computer.png") bottom / cover no-repeat;
}

.premium-connected__advantage--apps {
  background: #fde0d6 url("/images/premium/dist/non-svg/advantage-apps.png") bottom / contain no-repeat;
}

.premium-connected__advantage--tablet {
  background: url("/images/premium/dist/non-svg/advantage-tablet.png") bottom / cover no-repeat;
}

.premium-subscription {
  padding: 150px 0;
}

@media (max-width: 400px) {
  .premium-subscription {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.premium-subscription__logo {
  text-align: center;
  color: #a1a8ae;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: Poppins, helvetica, serif;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.premium-subscription__logo:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo--small.svg");
  background-size: 25px;
  width: 25px;
  height: 25px;
  margin-left: -12px;
  display: block;
  position: absolute;
  top: -50px;
  left: 50%;
}

.premium-subscription__info {
  text-align: center;
  color: #1f1e26;
}

.premium-subscription__info h1 {
  margin-top: 30px;
  font-family: Poppins, helvetica, serif;
  font-size: 90px;
  font-weight: normal;
  line-height: 1.11;
}

@media (max-width: 900px) {
  .premium-subscription__info h1 {
    font-size: 60px;
  }
}

@media (max-width: 400px) {
  .premium-subscription__info h1 {
    font-size: 40px;
  }
}

.premium-subscription__info > span {
  color: #666;
  margin-top: 25px;
  font-family: Poppins, helvetica, serif;
  font-size: 16px;
  display: block;
}

.premium-subscription__text {
  margin: 30px 0 0;
  font-family: Poppins, helvetica, serif;
  font-size: 20px;
  line-height: 1.4;
}

@media (max-width: 400px) {
  .premium-subscription__text {
    font-size: 13px;
  }
}

.premium-subscription__button {
  color: #fff;
  background-color: #f66533;
  margin: 40px 0 0;
  padding: 25px 50px;
  font-family: Poppins, helvetica, serif;
  transition: all .3s;
  display: inline-block;
}

.premium-subscription__button:hover {
  opacity: .8;
}

@media (max-width: 400px) {
  .premium-subscription__button {
    padding: 15px 30px;
  }
}

.premium-subscription__button span {
  font-family: Poppins, helvetica, serif;
}

.premium-faq--wrapper {
  background: #fff;
}

.premium-faq {
  color: #1f1f23;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 680px;
  max-width: calc(100% - 32px);
  margin: 0 auto;
  padding: 152px 0;
  display: flex;
}

@media (max-width: 400px) {
  .premium-faq {
    padding-top: 70px;
  }
}

.premium-faq__titles {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

.premium-faq__title {
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
}

.premium-faq__subtitle {
  text-align: center;
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.premium-faq__contact {
  align-items: center;
  gap: 8px;
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  display: flex;
}

.premium-faq__contact:hover a {
  color: #f66533;
}

.premium-faq__contact:hover svg path {
  fill: #f66533;
}

.premium-faq__contact a {
  text-decoration: underline;
}

.premium-faq__contact a:hover {
  text-decoration: none;
}

.premium-faq__info {
  display: inline-block;
}

.premium-faq__question {
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  padding: 24px 16px;
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.premium-faq__question:hover {
  color: #f66533;
}

@media (max-width: 500px) {
  .premium-faq__question {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .premium-faq__question {
    padding-right: 25px;
  }
}

@media (max-width: 400px) {
  .premium-faq__question {
    padding: 15px 25px 15px 0;
  }
}

.premium-faq__answer {
  height: 0;
  overflow: hidden;
}

.premium-faq__answer p {
  color: #000;
  padding-bottom: 0;
  font-family: Roboto, helvetica, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  transition: all .5s ease-in-out;
}

.premium-faq__answer p:last-child {
  padding-bottom: 0;
}

.premium-faq__answer p span {
  opacity: 0;
  transition: all .1s ease-in-out;
}

@media (max-width: 500px) {
  .premium-faq__answer p {
    font-size: 14px;
  }
}

.premium-faq__answer a {
  text-decoration: underline;
}

.premium-faq__answer a:hover {
  text-decoration: none;
}

.premium-faq .premium-contact__faq-block {
  border-bottom: 1px solid #f2eae4;
  padding-bottom: 0;
  transition: all .2s ease-in-out;
}

.premium-faq .premium-faq__question:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2225%22%20viewBox%3D%220%200%2024%2025%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M15.8751%209.50637L11.9951%2013.3864L8.1151%209.50637C7.7251%209.11637%207.0951%209.11637%206.7051%209.50637C6.3151%209.89637%206.3151%2010.5264%206.7051%2010.9164L11.2951%2015.5064C11.6851%2015.8964%2012.3151%2015.8964%2012.7051%2015.5064L17.2951%2010.9164C17.6851%2010.5264%2017.6851%209.89637%2017.2951%209.50637C16.9051%209.12637%2016.2651%209.11637%2015.8751%209.50637Z%22%20fill%3D%22%231F1F23%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  transition: all .3s ease-in-out;
}

.premium-faq .premium-contact__faq-block.open .premium-faq__question:after {
  transform: rotate(180deg);
}

.premium-faq .premium-contact__faq-block.open {
  padding-bottom: 25px;
}

.premium-faq .premium-contact__faq-block.open > .premium-faq__answer {
  height: auto;
  padding: 16px;
}

.premium-faq .premium-contact__faq-block.open > .premium-faq__answer p span {
  opacity: 1;
  transition: all .5s ease-in-out;
}

.premium-faq .premium-contact__faq-block.open > .premium-faq__answer p:not(:last-child) {
  padding-bottom: 15px;
}

.faq-info {
  background-image: linear-gradient(#f2eae4, #f7f7f7);
  padding: 70px 50px 50px 80px;
}

@media (max-width: 400px) {
  .faq-info {
    padding: 70px 20px 50px 50px;
  }
}

.faq-info h2 {
  color: #1f1e26;
  text-align: center;
  font-family: Poppins, Helvetica, serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
}

.faq-info h2 span {
  max-width: 390px;
  font-family: Poppins, Helvetica, serif;
  display: inline-block;
}

.faq-info h2:first-child:before {
  content: "";
  background-color: #fff;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--small.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  display: block;
  position: absolute;
  top: -40px;
  left: 50%;
}

.faq-info__question {
  color: #1f1e26;
  cursor: pointer;
  margin-bottom: 10px;
  padding-right: 20px;
  font-family: Poppins, Helvetica, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
}

.faq-info__question:before {
  content: "";
  background-color: #fff;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--small.svg");
  background-size: 15px;
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  top: 3px;
  left: -30px;
}

.faq-info__question:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 14px;
  height: 7px;
  margin-top: -4px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0;
}

.faq-info__answer {
  height: 0;
  font-family: Poppins, Helvetica, serif;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
}

.faq-info__answer p {
  padding-bottom: 10px;
  font-family: Poppins, Helvetica, serif;
  font-size: 16px;
  line-height: 1.5;
}

.faq-info__answer a {
  padding-right: 30px;
  font-family: Poppins, Helvetica, serif;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  color: #f66533 !important;
}

.faq-info__answer a:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/arrow-right-orange.svg");
  background-size: 24px;
  width: 24px;
  height: 24px;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 5px;
}

@media (max-width: 400px) {
  .faq-info__answer a:after {
    display: none;
  }
}

.faq-info__answer a:hover:after {
  right: 2px;
}

.faq-info__wrap {
  margin-bottom: 15px;
}

.faq-info__wrap.open .faq-info__answer {
  height: auto;
  padding-bottom: 15px;
}

.faq-info__wrap.open .faq-info__question:after {
  transform: rotate(180deg);
}

.faq-info__wrap--order-subscription {
  margin-bottom: 0;
}

.faq-info--order-subscription h2:before {
  content: none;
}

.faq-info.payment-articles h2 {
  margin-top: 10px;
  margin-bottom: 35px;
}

.faq-info.payment-articles h2:not(:first-of-type) {
  margin-top: 60px;
}

.faq-info.payment-articles h2:before {
  top: -25px;
}

.faq-info.payment-articles h2 span {
  font-size: 18px;
}

.profile-info .faq-info {
  margin-top: 50px;
}

.article-data {
  display: flex;
}

@media (max-width: 750px) {
  .article-data {
    flex-direction: column;
    align-items: center;
  }
}

.article-data__image {
  width: 270px;
}

@media (max-width: 750px) {
  .article-data__image {
    width: 400px;
  }
}

@media (max-width: 600px) {
  .article-data__image {
    width: 100%;
  }
}

.article-data__left {
  margin-left: 25px;
}

@media (max-width: 750px) {
  .article-data__left {
    width: 400px;
    margin-top: 15px;
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .article-data__left {
    width: 100%;
  }
}

.article-data__left img {
  max-height: 16px;
}

.article-data__left p {
  width: 270px;
  font-family: Poppins, Helvetica, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.5;
}

@media (max-width: 750px) {
  .article-data__left p {
    width: auto;
  }
}

.article-data__left a {
  margin-top: 20px;
}

@media (max-width: 750px) {
  .article-data__left a {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
  }
}

.premium-contact {
  background-color: #f8f8f8;
  padding: 60px 0 30px;
}

@media (max-width: 800px) {
  .premium-contact {
    padding: 48px 0 30px;
  }
}

.premium-contact__wrap {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 800px) {
  .premium-contact__wrap {
    flex-direction: column;
  }
}

@media (min-width: 401px) and (max-width: 800px) {
  .premium-contact__wrap {
    margin: 0 10vw;
  }
}

.premium-contact__side {
  font-family: Poppins, helvetica, serif;
}

.premium-contact__side--left {
  flex: 0 0 41%;
}

.premium-contact__side span {
  text-align: left;
}

.premium-contact__headline {
  color: #1f1e26;
  margin-bottom: 20px;
  font-size: 64px;
  line-height: 1.3;
}

@media (max-width: 800px) {
  .premium-contact__headline {
    text-align: center;
    font-size: 45px;
  }
}

@media (max-width: 400px) {
  .premium-contact__headline {
    font-size: 32px;
    line-height: 1.5;
  }
}

.premium-contact__text {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.premium-contact__form textarea, .premium-contact__form select {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #efefef;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-family: Poppins, helvetica, serif;
  line-height: 35px;
}

.premium-contact__form input:not([type="submit"]) {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #efefef;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-family: Poppins, helvetica, serif;
  line-height: 35px;
}

.premium-contact__form input:not([type="submit"])::placeholder {
  color: rgba(66, 71, 74, .25);
  font-size: 16px;
}

.premium-contact__form textarea::placeholder {
  color: rgba(66, 71, 74, .25);
  font-size: 16px;
}

.premium-contact__form select::placeholder {
  color: rgba(66, 71, 74, .25);
  font-size: 16px;
}

.premium-contact__form input:not([type="submit"]):focus::placeholder {
  color: rgba(0, 0, 0, 0);
}

.premium-contact__form textarea:focus::placeholder {
  color: rgba(0, 0, 0, 0);
}

.premium-contact__form select:focus::placeholder {
  color: rgba(0, 0, 0, 0);
}

.premium-contact__form input:not([type="submit"])[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.premium-contact__form input:not([type="submit"])[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.premium-contact__form textarea[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.premium-contact__form textarea[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.premium-contact__form select[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.premium-contact__form select[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.premium-contact__form textarea.input-error, .premium-contact__form select.input-error {
  border-color: red;
  outline: none;
}

.premium-contact__form input:not([type="submit"]).input-error {
  border-color: red;
  outline: none;
}

.premium-contact__form textarea {
  resize: none;
  height: 115px;
}

.premium-contact__form .form-group--select {
  position: relative;
}

.premium-contact__form .form-group--select:before {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 16px;
  height: 7px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50px;
  right: 20px;
}

.premium-contact__form .form-group {
  color: #1f1e26;
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  display: block;
}

.premium-contact__form .form-group:first-of-type {
  margin: 15px 0 0;
}

.premium-contact__form .form-group .input-error-message {
  text-align: right;
  color: red;
  margin: 5px 0 0;
  display: none;
}

.premium-contact__form .form-group[data-invalid]:after {
  text-align: right;
  color: red;
  margin: 5px 0 0;
  display: block;
}

.premium-contact__form .form-group[data-invalid="empty"]:after {
  content: attr(data-error-empty);
}

.premium-contact__form .form-group[data-invalid="format"]:after {
  content: attr(data-error-format);
}

.premium-contact__form-info {
  color: #666;
  margin: 5px 0 15px;
  font-size: 10px;
}

.premium-contact__form input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
  opacity: 1;
  background-color: #f66533;
  border: none;
  margin-top: 14px;
  margin-left: auto;
  padding: 16px 32px;
  font-size: 16px;
}

.premium-contact__form input[type="submit"]:hover {
  opacity: .8;
  cursor: pointer;
}

@media (max-width: 400px) {
  .premium-contact__form input[type="submit"] {
    width: 100%;
  }
}

.premium-contact__form .message-error {
  color: red;
  margin: 16px 0 0;
  display: none;
}

@media (max-width: 800px) {
  .premium-contact__form .message-error {
    text-align: center;
  }
}

.premium-contact__form .message-error--show {
  display: block;
}

.premium-contact .premium-faq {
  flex: 0 0 50%;
  padding-top: 0;
}

@media (max-width: 800px) {
  .premium-contact .premium-faq {
    border-top: 1px solid rgba(31, 30, 38, .1);
    margin-top: 48px;
    padding-top: 33px;
  }
}

.premium-contact .premium-faq__info {
  width: 100%;
}

.premium-contact .premium-contact__faq-block {
  border-bottom: 1px solid rgba(31, 30, 38, .1);
}

.premium-tabheader {
  background-color: #1f1e26;
  height: 1745px;
  padding-top: 30px;
  position: relative;
}

@media (max-width: 1710px) {
  .premium-tabheader {
    height: 1300px;
  }
}

@media (max-width: 1100px) {
  .premium-tabheader {
    height: 760px;
  }
}

@media (max-width: 750px) {
  .premium-tabheader {
    height: 920px;
  }
}

.premium-tabheader.white-bg {
  background-color: rgba(0, 0, 0, 0);
}

.premium-tabheader.white-bg .magazine-logo {
  display: none;
}

.premium-tabheader__bottom {
  background-color: #f7f7f7;
  width: 100%;
  height: 500px;
  position: absolute;
  bottom: 0;
}

@media (max-width: 1100px) {
  .premium-tabheader__bottom {
    height: 210px;
  }
}

.premium-tabheader__bottom:before {
  content: "";
  background-color: #f0f0f0;
  width: 45%;
  height: 500px;
  position: absolute;
  bottom: 0;
}

@media (max-width: 1100px) {
  .premium-tabheader__bottom:before {
    height: 210px;
  }
}

.magazine-logo {
  opacity: .5;
  height: 480px;
  position: relative;
  overflow: hidden;
}

.magazine-logo__wrapper {
  text-align: center;
  width: 2200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.magazine-logo__wrapper.second-row {
  margin-left: -125px;
  top: 145px;
}

@media (max-width: 750px) {
  .magazine-logo__wrapper.second-row {
    top: 55px;
  }
}

.magazine-logo__wrapper.third-row {
  top: 290px;
}

@media (max-width: 750px) {
  .magazine-logo__wrapper.third-row {
    top: 110px;
  }
}

.magazine-logo__item {
  vertical-align: middle;
  margin: 0 5px;
  display: inline-block;
}

@media (max-width: 750px) {
  .magazine-logo__item {
    margin: 0 2px;
  }
}

.magazine-logo__img {
  background-color: #23222a;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 130px;
  display: flex;
}

@media (max-width: 750px) {
  .magazine-logo__img {
    width: 85px;
    height: 50px;
  }
}

.magazine-logo__img img {
  width: auto;
  height: auto;
  max-height: 23px;
}

@media (max-width: 750px) {
  .magazine-logo__img img {
    width: auto;
    max-height: 13px;
  }
}

.premium-article {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.premium-article__item {
  z-index: 2;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 550px;
  border-radius: 3px;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  height: 685px;
  display: flex;
  position: absolute;
  left: 50%;
}

.premium-article__item--top {
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
  display: flex;
}

.premium-article__item--logo {
  height: 30px;
}

.premium-article__item--logo img {
  width: auto;
  max-height: 30px;
}

.premium-article__item--premium {
  background-color: #fff;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--small.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.premium-article__item--shadow {
  background-image: linear-gradient(rgba(0, 0, 0, 0), #16161a);
  height: 170px;
}

@media (max-width: 1100px) {
  .premium-article__item--shadow {
    height: 70px;
  }
}

.premium-article__item--bottom {
  align-self: flex-end;
  width: 100%;
}

.premium-article__item--bottom span {
  background-color: #16161a;
  align-items: flex-end;
  min-height: 150px;
  display: flex;
}

@media (max-width: 1100px) {
  .premium-article__item--bottom span {
    min-height: 100px;
  }
}

.premium-article__item--bottom p {
  color: #fff;
  padding: 15px;
  font-family: Poppins, helvetica, serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
}

.premium-article__item:first-child {
  margin-left: -225px;
  top: 0;
}

.premium-article__item:nth-child(2) {
  margin-left: -225px;
  top: 710px;
}

.premium-article__item:nth-child(3) {
  margin-left: -700px;
  top: 342.5px;
}

.premium-article__item:nth-child(4) {
  margin-left: 250px;
  top: 342.5px;
}

.premium-article__item:nth-child(5) {
  margin-left: -700px;
  top: 1052.5px;
}

.premium-article__item:nth-child(6) {
  margin-left: 250px;
  top: 1052.5px;
}

.premium-article__item:nth-child(7) {
  margin-left: -1175px;
  top: 710px;
}

.premium-article__item:nth-child(8) {
  margin-left: 725px;
  top: 710px;
}

@media (max-width: 1710px) {
  .premium-article__item {
    background-size: auto 300px;
    width: 300px;
    height: 450px;
  }

  .premium-article__item--top {
    margin: 15px;
  }

  .premium-article__item--bottom p {
    padding: 15px;
    font-size: 20px;
  }

  .premium-article__item--logo {
    height: 20px;
  }

  .premium-article__item--logo img {
    max-height: 20px;
  }

  .premium-article__item:first-child {
    margin-left: -150px;
    top: 0;
  }

  .premium-article__item:nth-child(2) {
    margin-left: -150px;
    top: 475px;
  }

  .premium-article__item:nth-child(3) {
    margin-left: -475px;
    top: 225px;
  }

  .premium-article__item:nth-child(4) {
    margin-left: 175px;
    top: 225px;
  }

  .premium-article__item:nth-child(5) {
    margin-left: -475px;
    top: 700px;
  }

  .premium-article__item:nth-child(6) {
    margin-left: 175px;
    top: 700px;
  }

  .premium-article__item:nth-child(7) {
    margin-left: -800px;
    top: 475px;
  }

  .premium-article__item:nth-child(8) {
    margin-left: 500px;
    top: 475px;
  }
}

@media (max-width: 1100px) {
  .premium-article__item {
    width: 205px;
    height: 310px;
  }

  .premium-article__item--top {
    margin: 15px;
  }

  .premium-article__item--bottom p {
    font-size: 13px;
  }

  .premium-article__item--logo {
    height: 20px;
  }

  .premium-article__item--logo img {
    max-height: 20px;
  }

  .premium-article__item:first-child {
    margin-left: -102.5px;
    top: 0;
  }

  .premium-article__item:nth-child(2) {
    margin-left: -102.5px;
    top: 315px;
  }

  .premium-article__item:nth-child(3) {
    margin-left: -312.5px;
    top: 155px;
  }

  .premium-article__item:nth-child(4) {
    margin-left: 107.5px;
    top: 155px;
  }

  .premium-article__item:nth-child(5) {
    margin-left: -312.5px;
    top: 470px;
  }

  .premium-article__item:nth-child(6) {
    margin-left: 107.5px;
    top: 470px;
  }

  .premium-article__item:nth-child(7) {
    margin-left: -522.5px;
    top: 315px;
  }

  .premium-article__item:nth-child(8) {
    margin-left: 317.5px;
    top: 315px;
  }
}

@media (max-width: 750px) {
  .premium-article__item:nth-child(7) {
    margin-left: -102.5px;
    top: 630px;
  }

  .premium-article__item:nth-child(8) {
    display: none;
  }
}

.premium-devices {
  padding-top: 100px;
  padding-bottom: 190px;
  overflow: hidden;
}

.premium-devices__wrapper {
  background-color: #f0f0f0;
  justify-content: space-between;
  align-items: center;
  height: 1255px;
  display: flex;
}

@media (max-width: 1100px) {
  .premium-devices__wrapper {
    height: 560px;
  }
}

@media (max-width: 750px) {
  .premium-devices__wrapper {
    flex-direction: column;
    height: auto;
  }
}

.premium-devices__left {
  flex-direction: row-reverse;
  width: 50%;
  display: flex;
}

@media (max-width: 750px) {
  .premium-devices__left {
    width: 100%;
    margin-top: 170px;
    margin-bottom: 150px;
    display: block;
  }
}

.premium-devices__left--text {
  max-width: 460px;
  margin-right: 60px;
}

@media (max-width: 1100px) {
  .premium-devices__left--text {
    margin-left: 20px;
  }
}

@media (max-width: 750px) {
  .premium-devices__left--text {
    text-align: center;
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.premium-devices__left--text h3 {
  margin: 0 0 20px;
  font-family: Poppins, helvetica, serif;
  font-size: 60px;
  line-height: 1.3;
  position: relative;
}

@media (max-width: 1100px) {
  .premium-devices__left--text h3 {
    font-size: 48px;
  }
}

@media (max-width: 400px) {
  .premium-devices__left--text h3 {
    font-size: 32px;
  }
}

.premium-devices__left--text h3:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo--small.svg");
  background-size: 25px;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  top: -50px;
}

@media (max-width: 750px) {
  .premium-devices__left--text h3:after {
    margin-left: -12px;
    left: 50%;
  }
}

.premium-devices__left--text p {
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 400px) {
  .premium-devices__left--text p {
    font-size: 16px;
  }
}

.premium-devices__right {
  width: 50%;
  height: 100%;
  position: relative;
}

@media (max-width: 1100px) {
  .premium-devices__right {
    width: 390px;
  }
}

@media (max-width: 750px) {
  .premium-devices__right {
    width: 590px;
    height: 490px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .premium-devices__right {
    width: 480px;
  }
}

.premium-devices__right--desktop {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/non-svg/isport-desktop-small.webp");
  background-repeat: no-repeat;
  border-radius: 28px 0 0 28px;
  width: 688px;
  height: 517px;
  position: absolute;
  top: 90px;
  right: 0;
  box-shadow: 0 0 35px #4c4c4c;
}

@media (max-width: 1870px) {
  .premium-devices__right--desktop {
    right: -100px;
  }
}

@media (max-width: 1730px) {
  .premium-devices__right--desktop {
    right: -190px;
  }
}

@media (max-width: 1500px) {
  .premium-devices__right--desktop {
    right: -310px;
  }
}

@media (max-width: 1280px) {
  .premium-devices__right--desktop {
    right: -410px;
  }
}

@media (max-width: 1100px) {
  .premium-devices__right--desktop {
    background-size: 325px;
    border-radius: 13px 0 0 13px;
    width: 254px;
    height: 232px;
    right: 0;
  }
}

@media (max-width: 750px) {
  .premium-devices__right--desktop {
    border-radius: 13px;
    width: 325px;
    top: -40px;
    right: 3%;
  }
}

.premium-devices__right--tablet {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/non-svg/isport-tablet-small.webp");
  background-repeat: no-repeat;
  border-radius: 28px 0 0 28px;
  width: 400px;
  height: 724px;
  position: absolute;
  bottom: -130px;
  right: 0;
  box-shadow: 0 0 35px #4c4c4c;
}

@media (max-width: 1870px) {
  .premium-devices__right--tablet {
    right: -100px;
  }
}

@media (max-width: 1730px) {
  .premium-devices__right--tablet {
    right: -165px;
  }
}

@media (max-width: 1500px) {
  .premium-devices__right--tablet {
    right: -30px;
  }
}

@media (max-width: 1280px) {
  .premium-devices__right--tablet {
    right: -125px;
  }
}

@media (max-width: 1100px) {
  .premium-devices__right--tablet {
    background-size: 226px;
    border-radius: 13px 0 0 13px;
    width: 133px;
    height: 316px;
    right: 0;
  }
}

@media (max-width: 750px) {
  .premium-devices__right--tablet {
    border-radius: 13px;
    width: 226px;
    top: 210px;
  }
}

@media (max-width: 390px) {
  .premium-devices__right--tablet {
    right: 0;
  }
}

.premium-devices__right--mobil {
  border-radius: 38px;
  flex-direction: column;
  justify-content: space-between;
  width: 231px;
  height: 474px;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 0 35px #4c4c4c;
}

.premium-devices__right--mobil--cover, .premium-devices__right--mobil--image {
  background-position-x: center;
  background-size: cover;
  border-radius: 37px;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--cover, .premium-devices__right--mobil--image {
    border-radius: 18px;
  }
}

.premium-devices__right--mobil--cover {
  z-index: 3;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/mobil.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.premium-devices__right--mobil--top {
  z-index: 4;
  flex-direction: row;
  justify-content: space-between;
  margin: 35px 20px 5px;
  display: flex;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--top {
    margin: 15px 10px 5px;
  }
}

.premium-devices__right--mobil--logo {
  height: 20px;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--logo {
    height: 10px;
  }
}

.premium-devices__right--mobil--logo img {
  vertical-align: top;
  width: auto;
  max-height: 20px;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--logo img {
    max-height: 10px;
  }
}

.premium-devices__right--mobil--premium {
  background-color: #fff;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--small.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--premium {
    background-size: 10px;
    width: 10px;
    height: 10px;
  }
}

.premium-devices__right--mobil--bottom {
  z-index: 4;
  align-self: flex-end;
}

.premium-devices__right--mobil--bottom p:first-child {
  color: #fff;
  padding: 15px 20px;
  font-family: Poppins, helvetica, serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--bottom p:first-child {
    padding: 7px 10px 4px;
    font-size: 10px;
  }
}

.premium-devices__right--mobil--bottom p:last-child {
  color: #000;
  background-color: #fff;
  border-radius: 0 0 25px 25px;
  margin: 0 13px 12px;
  padding: 15px 7px;
  font-family: Poppins, helvetica, serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil--bottom p:last-child {
    border-radius: 0 0 12px 12px;
    margin: 0 6px 5px;
    padding: 7px 5px;
    font-size: 5px;
  }

  .premium-devices__right--mobil {
    border-radius: 18px;
    width: 107px;
    height: 217px;
  }
}

.premium-devices__right--mobil:first-child {
  top: -30px;
  left: 0;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil:first-child {
    display: flex;
    top: -30px;
  }
}

@media (max-width: 750px) {
  .premium-devices__right--mobil:first-child {
    margin-left: -55px;
    top: 250px;
    left: 50%;
  }
}

@media (max-width: 600px) {
  .premium-devices__right--mobil:first-child {
    left: 41%;
  }
}

@media (max-width: 400px) {
  .premium-devices__right--mobil:first-child {
    margin-left: auto;
    left: 125px;
  }
}

.premium-devices__right--mobil:nth-child(2) {
  top: 504px;
  left: 0;
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil:nth-child(2) {
    display: flex;
    top: 220px;
  }
}

@media (max-width: 750px) {
  .premium-devices__right--mobil:nth-child(2) {
    margin-left: -55px;
    top: -20px;
    left: 30%;
  }
}

@media (max-width: 600px) {
  .premium-devices__right--mobil:nth-child(2) {
    left: 17%;
  }
}

@media (max-width: 400px) {
  .premium-devices__right--mobil:nth-child(2) {
    margin-left: auto;
    left: 10px;
  }
}

.premium-devices__right--mobil:nth-child(3) {
  top: 660px;
  left: 260px;
}

@media (max-width: 1500px) {
  .premium-devices__right--mobil:nth-child(3) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .premium-devices__right--mobil:nth-child(3) {
    display: flex;
    top: 375px;
    left: 140px;
  }
}

@media (max-width: 850px) {
  .premium-devices__right--mobil:nth-child(3) {
    left: 115px;
  }
}

@media (max-width: 750px) {
  .premium-devices__right--mobil:nth-child(3) {
    margin-left: -55px;
    top: 300px;
    left: 30%;
  }
}

@media (max-width: 600px) {
  .premium-devices__right--mobil:nth-child(3) {
    left: 17%;
  }
}

@media (max-width: 400px) {
  .premium-devices__right--mobil:nth-child(3) {
    margin-left: auto;
    left: 10px;
  }
}

.premium-advantages {
  position: relative;
}

.premium-advantages:before {
  content: "";
  background-color: #f0f0f0;
  width: 45%;
  height: 25%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 550px) {
  .premium-advantages:before {
    display: none;
  }
}

.premium-advantages__item {
  width: 60vw;
  padding-top: 200px;
  padding-bottom: 200px;
}

@media (max-width: 550px) {
  .premium-advantages__item {
    width: 70vw;
    padding-right: 50px;
  }
}

@media (max-width: 450px) {
  .premium-advantages__item {
    width: 85vw;
    padding-right: 20px;
  }
}

.premium-advantages__item:first-child {
  padding-left: 550px;
}

@media (max-width: 1700px) {
  .premium-advantages__item:first-child {
    padding-left: 420px;
  }
}

@media (max-width: 1500px) {
  .premium-advantages__item:first-child {
    padding-left: 330px;
  }
}

@media (max-width: 1300px) {
  .premium-advantages__item:first-child {
    padding-left: 230px;
  }
}

@media (max-width: 1200px) {
  .premium-advantages__item:first-child {
    padding-left: 180px;
  }
}

@media (max-width: 1000px) {
  .premium-advantages__item:first-child {
    padding-left: 150px;
  }
}

@media (max-width: 650px) {
  .premium-advantages__item:first-child {
    padding-left: 100px;
  }
}

@media (max-width: 550px) {
  .premium-advantages__item:first-child {
    padding-left: 50px;
  }
}

@media (max-width: 450px) {
  .premium-advantages__item:first-child {
    padding-left: 20px;
  }
}

.premium-advantages__item:not(:first-child) {
  padding-left: 150px;
}

@media (max-width: 550px) {
  .premium-advantages__item:not(:first-child) {
    padding-left: 50px;
  }
}

@media (max-width: 450px) {
  .premium-advantages__item:not(:first-child) {
    padding-left: 20px;
  }
}

.premium-advantages__item:last-child {
  padding-right: 250px;
}

@media (max-width: 850px) {
  .premium-advantages__item:last-child {
    padding-right: 200px;
  }
}

@media (max-width: 750px) {
  .premium-advantages__item:last-child {
    padding-right: 150px;
  }
}

@media (max-width: 550px) {
  .premium-advantages__item:last-child {
    padding-right: 100px;
  }
}

@media (max-width: 450px) {
  .premium-advantages__item:last-child {
    padding-right: 50px;
  }
}

@media (max-width: 320px) {
  .premium-advantages__item:last-child {
    padding-right: 30px;
  }
}

.premium-advantages__item h3 {
  color: #27272e;
  max-width: 800px;
  margin: 0 0 20px;
  font-family: Poppins, helvetica, serif;
  font-size: 60px;
  line-height: 1.3;
  position: relative;
}

@media (max-width: 1100px) {
  .premium-advantages__item h3 {
    font-size: 48px;
  }
}

@media (max-width: 850px) {
  .premium-advantages__item h3 {
    font-size: 35px;
  }
}

@media (max-width: 650px) {
  .premium-advantages__item h3 {
    font-size: 28px;
  }
}

@media (max-width: 300px) {
  .premium-advantages__item h3 {
    font-size: 20px;
  }
}

.premium-advantages__item p {
  max-width: 390px;
  padding-bottom: 30px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 750px) {
  .premium-advantages__item p {
    font-size: 16px;
  }
}

@media (max-width: 300px) {
  .premium-advantages__item p {
    padding-bottom: 15px;
    font-size: 14px;
  }
}

.premium-advantages__item a {
  padding-right: 30px;
  font-family: Poppins, helvetica, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.4;
  transition: all .3s;
  position: relative;
}

@media (max-width: 350px) {
  .premium-advantages__item a {
    font-size: 14px;
  }
}

@media (max-width: 300px) {
  .premium-advantages__item a {
    font-size: 12px;
  }
}

.premium-advantages__item a:last-child {
  margin-top: 15px;
}

.premium-advantages__item a:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/arrow-right.svg");
  background-size: 24px;
  width: 24px;
  height: 24px;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 5px;
}

.premium-advantages__item a:hover {
  opacity: .2;
}

.premium-advantages__item a:hover:after {
  right: 2px;
}

.premium-advantages__item--number {
  color: #86868a;
  border-top: 2px solid #86868a;
  width: 80px;
  padding-top: 25px;
  font-family: Poppins, helvetica, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: -100px;
}

@media (max-width: 650px) {
  .premium-advantages__item--number {
    width: 50px;
    top: 10px;
    left: -70px;
  }
}

@media (max-width: 550px) {
  .premium-advantages__item--number {
    width: 30px;
    padding-top: 15px;
    font-size: 16px;
    top: -55px;
    left: 0;
  }
}

.premium-advantages section.horizontal {
  overflow-x: hidden;
}

.premium-advantages section.horizontal .pin-wrap, .premium-advantages section.horizontal .animation-wrap {
  z-index: 1;
  height: 100vh;
  display: flex;
  position: relative;
}

.premium-overview__title {
  color: #1f1e26;
  margin-top: 150px;
}

@media (max-width: 750px) {
  .premium-overview__title {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.premium-overview__title h1 {
  text-align: center;
  font-family: Poppins, helvetica, serif;
  font-size: 64px;
  font-weight: 500;
}

@media (max-width: 750px) {
  .premium-overview__title h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 54px;
  }
}

.premium-overview__title p {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 45px;
  font-family: Poppins, helvetica, serif;
  font-size: 20px;
  line-height: normal;
}

@media (max-width: 750px) {
  .premium-overview__title p {
    font-size: 18px;
  }
}

.premium-overview__content {
  justify-content: space-between;
  max-width: 1035px;
  margin-bottom: 150px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

@media (max-width: 750px) {
  .premium-overview__content {
    padding-top: 30px;
    display: block;
  }
}

.premium-overview__hidden-header {
  display: none;
}

@media (max-width: 750px) {
  .premium-overview__hidden-header {
    width: auto;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .premium-overview__hidden-header span {
    border-top: 1px solid #4c4c4c;
    margin-top: 20px;
    margin-bottom: 60px;
    padding-top: 20px;
    font-family: Poppins, helvetica, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    line-height: 1.4;
    display: block;
    position: relative;
  }

  .premium-overview__hidden-header span:after {
    content: "";
    background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/arrow-down.svg");
    background-repeat: no-repeat;
    width: 17px;
    height: 7px;
    margin-top: 8px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  }
}

.premium-overview__left {
  width: 210px;
  height: 400px !important;
  max-height: none !important;
}

@media (max-width: 750px) {
  .premium-overview__left {
    width: auto;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    height: auto !important;
  }
}

.premium-overview__left span {
  opacity: .3;
  cursor: pointer;
  border-top: 1px solid #4c4c4c;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.4;
  transition: all .2s;
  display: block;
  position: relative;
}

.premium-overview__left span:first-child {
  margin-top: 0;
}

@media (max-width: 750px) {
  .premium-overview__left span {
    opacity: 1;
    pointer-events: none;
  }
}

.premium-overview__left span:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/arrow-down.svg");
  background-repeat: no-repeat;
  width: 17px;
  height: 7px;
  margin-top: 8px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(270deg);
}

@media (max-width: 750px) {
  .premium-overview__left span:after {
    transform: none;
  }
}

.premium-overview__left span:hover {
  opacity: 1 !important;
}

@media (max-width: 750px) {
  .premium-overview__left span:not(:first-child) {
    display: none;
  }
}

.premium-overview__item {
  border-bottom: 1px solid #f2eae4;
  margin-top: 100px;
  display: flex;
}

.premium-overview__item:first-child {
  margin-top: 0;
}

@media (max-width: 1000px) {
  .premium-overview__item {
    max-width: 400px;
    margin-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
}

@media (max-width: 750px) {
  .premium-overview__item {
    margin-left: auto;
    margin-right: auto;
  }
}

.premium-overview__item--img {
  text-align: center;
  background-color: #1f1e26;
  background-image: linear-gradient(90deg, #f7f7f7 9px, rgba(0, 0, 0, 0) 1%), linear-gradient(#f7f7f7 9px, rgba(0, 0, 0, 0) 1%), none;
  background-position: center, center, 0 0;
  background-repeat: repeat, repeat, repeat;
  background-size: 10px 10px;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 300px;
  height: 110px;
  margin-right: 85px;
}

@media (max-width: 1000px) {
  .premium-overview__item--img {
    width: auto;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

.premium-overview__item--img img {
  background-color: #f7f7f7;
  max-height: 30px;
  margin-top: 35px;
  padding: 10px 20px;
}

.premium-overview__item--text {
  color: #1f1e26;
  width: 300px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
}

@media (max-width: 1000px) {
  .premium-overview__item--text {
    box-sizing: border-box;
    width: auto;
    padding-left: 30px;
  }
}

.premium-overview__item--text ul {
  margin: 0 0 60px;
  padding: 0;
}

.premium-overview__item--text ul li {
  margin-bottom: 12px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  line-height: 1.5;
  list-style: none;
  position: relative;
}

.premium-overview__item--text ul li b {
  font-family: Poppins, helvetica, serif;
}

.premium-overview__item--text ul li:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/checkmark-grey.svg");
  background-repeat: no-repeat;
  width: 13px;
  height: 10px;
  display: block;
  position: absolute;
  top: 9px;
  left: -25px;
}

.premium-overview__logotypes {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 685px;
  display: flex;
  padding-top: 140px !important;
}

@media (max-width: 1000px) {
  .premium-overview__logotypes {
    max-width: 400px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 750px) {
  .premium-overview__logotypes {
    margin-left: auto;
    margin-right: auto;
  }
}

.premium-overview__logotypes .premium-overview__item--img {
  margin-bottom: 80px;
  margin-right: 0;
}

@media (max-width: 1000px) {
  .premium-overview__logotypes .premium-overview__item--img {
    width: 150px;
    height: 80px;
    margin-bottom: 50px;
  }

  .premium-overview__logotypes img {
    max-width: 120px;
    max-height: 30px;
    margin-top: 17px;
    padding: 8px 10px;
  }
}

.premium-overview__logotypes .contentMarker.border-line-bottom {
  border-bottom: 1px solid #f2eae4;
  width: 100%;
  margin-bottom: 80px;
  display: block;
}

.hideForm, .boxTestHide {
  display: none;
}

.contentMarker {
  margin-top: -100px;
  padding-top: 100px;
}

.recentarticles-detail {
  width: 1300px;
  margin-top: 150px;
  font-family: Poppins, helvetica, serif;
}

@media (max-width: 1300px) {
  .recentarticles-detail {
    max-width: 840px;
  }
}

@media (max-width: 1020px) {
  .recentarticles-detail {
    width: 100%;
  }
}

.recentarticles-detail h1 {
  text-align: center;
  font-family: Poppins, helvetica, serif;
  font-size: 64px;
  font-weight: 500;
}

@media (max-width: 1020px) {
  .recentarticles-detail h1 {
    font-size: 45px;
    line-height: 1.2;
  }
}

.recentarticles-detail h2 {
  margin-bottom: 0;
  font-family: Poppins, helvetica, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
}

.recentarticles-detail .magazine {
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1300px) {
  .recentarticles-detail .magazine {
    max-width: 840px;
  }
}

@media (max-width: 1020px) {
  .recentarticles-detail .magazine {
    max-width: 320px;
  }
}

.recentarticles-detail .subtitle {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 45px;
  font-family: Poppins, helvetica, serif;
  font-size: 20px;
  line-height: 1;
}

@media (max-width: 1020px) {
  .recentarticles-detail .subtitle {
    margin-top: 15px;
    font-size: 17px;
  }
}

.recentarticles-detail .title {
  margin-top: 15px;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  display: block;
}

.recentarticles-detail .perex {
  font-size: 18px;
  line-height: 28px;
}

.recentarticles-detail .image-wrapper {
  width: 312px;
  height: 197px;
  overflow: hidden;
}

.recentarticles-detail .image {
  width: 312px;
  height: auto;
}

@media (max-width: 1020px) {
  .recentarticles-detail .image {
    width: 100%;
    height: auto;
  }
}

.recentarticles-detail .filters {
  white-space: nowrap;
  will-change: transform;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  margin-bottom: 150px;
  transition: all .2s;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.recentarticles-detail .filters::-webkit-scrollbar {
  display: none;
}

.recentarticles-detail .filters .filters.active {
  cursor: grabbing;
  background: rgba(255, 255, 255, .3);
}

@media (max-width: 1020px) {
  .recentarticles-detail .filters {
    margin-bottom: 40px;
  }
}

.recentarticles-detail .filter {
  box-sizing: border-box;
  cursor: pointer;
  background: rgba(242, 106, 27, .1);
  border: 1px solid #f66533;
  border-radius: 10px;
  width: 120px;
  height: 48px;
  margin-right: 16px;
  display: inline-block;
  position: relative;
}

.recentarticles-detail .filter .radio-outer {
  background: #fff;
  border: 5px solid #fff;
  border-radius: 22px;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.recentarticles-detail .filter.filter--full .radio-inner {
  background: #f66533;
  border: 2px solid #f66533;
  border-radius: 15px;
  width: 1px;
  height: 1px;
  position: absolute;
  top: -2px;
  left: -2px;
}

.recentarticles-detail .recentarticles--left {
  float: left;
  border-top: 1px solid rgba(31, 30, 38, .05);
  width: 288px;
  margin-right: 104px;
}

@media (max-width: 1300px) {
  .recentarticles-detail .recentarticles--left {
    float: none;
    margin-bottom: 15px;
  }
}

@media (max-width: 1020px) {
  .recentarticles-detail .recentarticles--left {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }
}

.recentarticles-detail .recentarticles--middle {
  float: left;
  width: 416px;
  margin-bottom: 90px;
}

@media (max-width: 1110px) {
  .recentarticles-detail .recentarticles--middle {
    float: none;
    width: 100%;
    margin-top: 250px;
  }
}

.recentarticles-detail .recentarticles--right {
  float: left;
  text-align: right;
  width: 416px;
}

@media (max-width: 1110px) {
  .recentarticles-detail .recentarticles--right {
    float: none;
    text-align: left;
    width: 100%;
    position: absolute;
    top: 118px;
    left: 0;
  }
}

.recentarticles-detail .cb {
  clear: both;
}

.recentarticles-detail .hidden {
  display: none;
}

.recentarticles-detail .logo-e15 {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/e15-logo.svg?v=3");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 17px;
  width: 81px;
}

.recentarticles-detail .logo-reflex {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/reflex-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
  width: 101px;
}

.recentarticles-detail .logo-isport {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/isport-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
  width: 101px;
}

.recentarticles-detail .logo-blesk {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/blesk-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
  width: 101px;
}

.recentarticles-detail .logo-auto {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/auto-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
}

.recentarticles-detail .logo-maminka {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/maminka-logo-black.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
}

.recentarticles-detail .logo-bpz {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/bleskprozeny-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
}

.recentarticles-detail .logo-zive {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/zive-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
}

.recentarticles-detail .logo-abc {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/abc-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 15px;
  margin-right: 0;
}

.recentarticles-detail .magazine-link {
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  font-weight: 600;
}

.recentarticles-detail .premium-sticker {
  text-align: center;
  color: #f7f7f7;
  background-color: #f66533;
  width: 20px;
  height: 20px;
  font-size: 24px;
  font-weight: 900;
  line-height: .8;
}

.recentarticles-payment {
  justify-content: space-evenly;
  width: 710px;
  margin: 0 -50px 0 -80px;
  display: flex;
}

@media (max-width: 750px) {
  .recentarticles-payment {
    flex-direction: column;
    width: auto;
  }
}

.recentarticles-payment__item {
  max-width: 270px;
}

@media (max-width: 750px) {
  .recentarticles-payment__item {
    margin: 0 auto;
  }

  .recentarticles-payment__item:first-child {
    margin-bottom: 40px;
  }
}

.recentarticles-payment__image {
  background-position: center;
  background-size: cover;
  width: 270px;
  height: 161px;
}

.recentarticles-payment__image a {
  width: 100%;
  height: 100%;
  display: block;
}

.recentarticles-payment__magazine-logo {
  max-height: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.recentarticles-payment p {
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.5;
}

.recentarticles-payment p a {
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.5;
  text-decoration: none !important;
}

.recentarticles-payment p a:hover {
  text-decoration: underline !important;
}

.form-review {
  margin-bottom: 100px;
}

.form-review__field {
  margin: 8px 0;
}

.form-review__field input {
  display: none;
}

.form-review__field label {
  cursor: pointer;
  background-color: #f2f2f2;
  padding: 12px 16px 12px 42px;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  display: block;
  position: relative;
}

.form-review__field label:after {
  content: "";
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #1f1e26;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: block;
  position: absolute;
  top: 13px;
  left: 12px;
}

.form-review__field textarea {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 130px;
  min-height: 100px;
  padding: 10px;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  display: none;
}

.form-review__field textarea:focus-visible {
  outline: none;
}

.form-review__field input:checked + label {
  color: #f66533;
  background-color: #f7e8e3;
}

.form-review__field input:checked + label:after {
  content: "✔";
  color: #fff;
  background-color: #f66533;
  border-color: #f66533;
}

.form-review__field input[id="choice8"]:checked + label + div > textarea {
  display: block;
}

.form-review__buttons {
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

@media (max-width: 550px) {
  .form-review__buttons {
    display: block;
  }

  .form-review__buttons button {
    box-sizing: border-box;
    width: 100%;
  }
}

.form-review__buttons a, .form-review__buttons button {
  margin: 0 15px;
}

.form-review__send {
  display: none;
}

.clear {
  clear: both;
}

.newsletters {
  justify-content: flex-start;
  width: 1224px;
  margin: 92px auto 24px;
  scroll-margin-top: 156px;
  font-family: Poppins, helvetica, serif;
  display: flex;
}

.newsletters ~ .newsletters {
  margin: 24px auto;
}

.newsletters .newsletters__magazine {
  border-top: 1px solid rgba(31, 30, 38, .25);
  width: 288px;
}

.newsletters .newsletters__magazine .newsletters__magazine-logo {
  max-height: 30px;
  margin-top: 32px;
}

.newsletters .newsletters__details {
  flex-flow: wrap;
  justify-content: flex-start;
  width: calc(100% - 288px);
  display: flex;
}

.newsletters .newsletters__details .newsletter__detail {
  background: #fff;
  width: 240px;
  height: 228px;
  margin: 0 12px 24px;
  padding: 24px 24px 19px;
  scroll-margin-top: 156px;
  position: relative;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--premium {
  float: left;
  background-color: #fff;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--small.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--date {
  color: rgba(29, 29, 31, .6);
  float: left;
  background: #f0f0f0;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--title {
  margin: 17px 0 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--description {
  color: #1f1e26;
  max-height: 112px;
  font-size: 14px;
  line-height: 22px;
  overflow-y: hidden;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions {
  border-top: 1px solid rgba(240, 240, 240, .9);
  justify-content: space-between;
  width: calc(100% - 48px);
  padding: 18px 24px 19px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions.newsletter__detail--gradient {
  background-image: linear-gradient(to left, #fff, #f0f0f0);
  border-top: 1px solid rgba(0, 0, 0, 0);
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions.selected {
  background-image: linear-gradient(to left, #fff, rgba(246, 101, 51, .2));
  border-top: 1px solid rgba(0, 0, 0, 0);
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions .newsletter__detail--signedup {
  color: rgba(31, 30, 38, .6);
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions .newsletter__detail--signup {
  color: #f66533;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  min-width: 90px;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions .newsletter__detail--signup:hover {
  text-decoration: underline;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions .newsletter__detail--signup:before {
  content: "";
  float: left;
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/plus.svg");
  width: 16px;
  height: 16px;
  margin-right: 12px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions .newsletter__detail--signup.selected:before {
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/check.svg");
}

.newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions .newsletter__detail--preview {
  color: #1f1e26;
  cursor: pointer;
  text-decoration: underline;
}

section.newsletters:last-of-type {
  margin-bottom: 140px;
}

.newsletters__filters-wrap {
  height: 66px;
  margin: 48px auto;
  display: block;
}

.newsletters__filters {
  flex-wrap: wrap;
  justify-content: center;
  width: 1224px;
  margin: 48px auto;
  display: flex;
}

.newsletters__filters .newsletters__filters-magazine {
  cursor: pointer;
  background: rgba(242, 106, 27, .1);
  border-radius: 100px;
  margin: 5px;
  padding: 18px 24px;
}

.newsletters__filters .newsletters__filters-magazine .newsletters__filters-logo {
  height: 16px;
}

.newsletters__bar {
  z-index: 1;
  background: #fff;
  width: 100%;
  padding: 20px 0;
  font-family: Poppins, helvetica, serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, .12);
}

.newsletters__bar .newsletters__bar-content {
  justify-content: space-between;
  width: 1224px;
  margin: 0 auto;
  display: flex;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-summary {
  color: #1f1e26;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  display: flex;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-summary .newsletters__bar-count {
  color: #f66533;
  margin-left: 10px;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-mobile-button {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: #f66533;
  border: 0;
  width: calc(100% - 36px);
  padding: 8px;
  font-family: Poppins, Helvetica, serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  display: none;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-form .newsletters__form-input {
  float: left;
  border: 1px solid rgba(31, 30, 38, .2);
  width: 290px;
  padding: 17px;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  line-height: 20px;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-form .newsletters__form-submit {
  color: #fff;
  cursor: pointer;
  background: #f66533;
  border: 0;
  width: 202px;
  padding: 18px 17px;
  font-family: Poppins, helvetica, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-form .newsletters__bar-agreement {
  color: rgba(31, 30, 38, .4);
  margin-top: 9px;
  font-size: 11px;
  line-height: 15px;
}

.newsletters__bar .newsletters__bar-content .newsletters__bar-form .newsletters__bar-agreement a {
  text-decoration: underline;
}

.newsletters__bar-completed-wrap {
  color: #fff;
  width: 100%;
  font-family: Poppins, helvetica, serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.newsletters__bar-completed-wrap .newsletters__bar-completed {
  background-color: #27272e;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 472px;
  margin: 0 auto;
  padding: 18px 31px;
  display: flex;
  box-shadow: 0 -4px 36px rgba(0, 0, 0, .12);
}

.newsletters__bar-completed-wrap .newsletters__bar-completed .newsletters__bar-completed-left {
  max-width: 430px;
}

.newsletters__bar-completed-wrap .newsletters__bar-completed .newsletters__bar-completed-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.newsletters__bar-completed-wrap .newsletters__bar-completed .newsletters__bar-completed-subtitle {
  color: rgba(255, 255, 255, .75);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.newsletters__bar-completed-wrap .newsletters__bar-completed .newsletters__bar-completed-cross {
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/cross.svg");
  width: 32px;
  height: 32px;
}

#navbar .newsletters__filters {
  background-color: #f7f7f7;
  border-top: 1px solid rgba(31, 30, 38, .05);
  margin: 0 auto;
  padding: 16px 0;
}

#navbar .newsletters__filters .newsletters__filters-magazine {
  padding: 8px 16px;
}

@media (max-width: 1224px) {
  .newsletters {
    width: 1035px;
    padding: 0 10px;
  }

  .newsletters__filters {
    flex-wrap: nowrap;
    width: 1035px;
  }

  .newsletters__bar .newsletters__bar-content {
    width: 1035px;
  }
}

@media (max-width: 1035px) {
  .newsletters {
    width: calc(100% - 64px);
    padding: 0 32px;
  }

  .newsletters .newsletters__magazine {
    width: 164px;
  }

  .newsletters .newsletters__magazine .newsletters__magazine-logo {
    height: 20px;
  }

  .newsletters .newsletters__details {
    width: calc(100% - 164px);
  }

  .newsletters .newsletters__details .newsletter__detail {
    width: 222px;
    height: 215px;
    margin: 0 8px 24px;
    padding: 24px 16px 19px;
  }

  .newsletters .newsletters__details .newsletter__detail .newsletter__detail--title {
    font-size: 18px;
    line-height: 27px;
  }

  .newsletters .newsletters__details .newsletter__detail .newsletter__detail--description {
    font-size: 13px;
    line-height: 20px;
  }

  .newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions {
    width: calc(100% - 32px);
    padding: 19px 16px;
  }

  .newsletters__filters {
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: calc(100% - 64px);
    margin: 32px 32px 64px;
    overflow: auto;
  }

  .newsletters__filters .newsletters__filters-magazine, .newsletters__filters .newsletters__filters-logo {
    min-width: -webkit-fit-content;
    min-width: fit-content;
  }

  .newsletters__bar .newsletters__bar-content {
    flex-direction: column;
    align-items: center;
    width: calc(100% - 64px);
    margin: 0 32px;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-summary {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .newsletters {
    flex-direction: column;
    width: calc(100% - 34px);
    margin: 64px 0;
    padding: 0 17px;
  }

  .newsletters .newsletters__magazine {
    text-align: center;
    width: 100%;
  }

  .newsletters .newsletters__magazine .newsletters__magazine-logo {
    height: 20px;
    margin: 48px 0 32px;
  }

  .newsletters .newsletters__details {
    width: 100%;
  }

  .newsletters .newsletters__details .newsletter__detail {
    width: 100%;
    margin: 0 0 24px;
    padding: 24px 24px 19px;
  }

  .newsletters .newsletters__details .newsletter__detail .newsletter__detail--actions {
    width: calc(100% - 48px);
    padding: 19px 24px;
  }

  .newsletters__filters {
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    margin: 32px 0 64px;
    overflow: auto;
  }

  .newsletters__filters .newsletters__filters-magazine {
    min-width: -webkit-fit-content;
    min-width: fit-content;
    padding: 14px 16px;
  }

  .newsletters__filters .newsletters__filters-logo {
    min-width: -webkit-fit-content;
    min-width: fit-content;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-summary {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-mobile-button {
    display: block;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-form {
    display: none;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-form.active {
    display: block;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-form .newsletters__form-input {
    width: calc(100% - 36px);
    margin-bottom: 8px;
  }

  .newsletters__bar .newsletters__bar-content .newsletters__bar-form .newsletters__form-submit {
    width: 100%;
  }
}

.delete-account {
  max-width: 917px;
  margin: auto;
  padding: 25px 15px 0;
}

.delete-account h1 {
  text-align: center;
  margin-bottom: 40px;
  padding: 0;
  font-size: 48px;
}

.delete-account h2 {
  margin-bottom: 20px;
  padding: 0;
  font-size: 20px;
}

.delete-account p {
  font-size: 16px;
  line-height: 28px;
}

.delete-account p a {
  color: #df0028;
}

.delete-container {
  padding: 25px 0;
}

.delete-login {
  text-align: right;
}

.del-title-list {
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
}

@media (max-width: 900px) {
  .del-title-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .del-title-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.del-title-list li {
  text-align: center;
  border: 1px solid #e7e7e7;
  justify-content: center;
  align-items: center;
  min-height: 53px;
  padding: 5px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
}

.del-title-list img {
  display: block;
}

.del-nextsteps {
  counter-reset: item;
  margin: 0;
  padding: 0;
  list-style: none;
}

.del-nextsteps li {
  counter-increment: item;
  margin-bottom: 15px;
  padding-left: 35px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
}

.del-nextsteps li:before {
  content: counter(item);
  color: #fff;
  text-align: center;
  background: #df0028;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}

.message-error {
  display: none;
}

.message-error--show {
  display: block;
}

.report-message {
  display: none;
}

.report-message--error {
  padding-top: 25px;
}

.delete-button {
  color: #fff;
  cursor: pointer;
  background: #df0028;
  border: 0;
  border-radius: 4px;
  margin-right: 20px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  display: inline-block;
}

.delete-cancelbutton {
  color: #df0028;
  background: #fff;
  border: 2px solid #df0028;
  border-radius: 4px;
  padding: 3px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  display: inline-block;
}

.delete-modal {
  z-index: 1;
  background-color: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.delete-modal.modal-opened {
  display: block;
}

.delete-modal-inner {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.delete-modal-form {
  background: #fff;
  padding: 20px;
}

.delete-modal-form--buttons {
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  padding: 20px 0 0;
  display: flex;
}

.delete-modal-form .delete-cancel-button {
  cursor: pointer;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.subscription {
  padding: 15px;
  position: relative;
}

.subscription.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(255, 255, 255, 0) 0% 2%, #fff 60% 100%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription__header {
  text-transform: uppercase;
  color: #000;
  flex-direction: column;
  align-items: center;
  margin-bottom: 23px;
  font-family: Poppins, helvetica, serif;
  font-size: 31px;
  font-weight: 700;
  line-height: 35px;
  display: flex;
}

.subscription__header--article {
  margin-bottom: 17px;
}

.subscription__welcome-text {
  margin-bottom: 8px;
}

.main-article .subscription__welcome-text {
  display: none;
}

.subscription__main-title {
  text-align: center;
  color: #fff;
  background: #cca550;
  margin: 0 auto 13px;
  padding: 13px 16px 0;
  font-size: 44px;
  line-height: 44px;
}

.subscription__main-title--article {
  letter-spacing: 1px;
  width: 116px;
  height: 32px;
  margin-bottom: 8px;
  padding: 4px 0 0;
  font-size: 24px;
  line-height: 38px !important;
}

.subscription__magazine-logo {
  width: 140px;
  height: 44px;
}

.subscription__magazine-logo--article {
  width: 86px;
  height: 28px;
}

.subscription__logo {
  width: 150px;
  height: 45px;
  margin: 0 auto 20px;
}

.subscription__logo.dataimg-icon-premium-welcome {
  width: 270px;
}

.subscription__headline {
  text-align: center;
  width: 85%;
  margin: 0 auto 15px;
  line-height: 44px;
  font-size: 40px !important;
}

.subscription__link {
  color: #2c2f3b;
  text-decoration: underline;
}

.subscription__link:hover {
  text-decoration: none;
}

.subscription__login {
  text-align: center;
  margin: -10px auto 25px;
  font-size: 14px;
  line-height: 20px;
}

.subscription__login-link {
  text-decoration: underline;
}

.subscription__login-link:hover {
  text-decoration: none;
}

.subscription__advantages {
  background: #f8f8f9 url("/images/reflex2016/standalone/premium/raoul.png") 100% 94% / 90px no-repeat;
}

.subscription__advantages-headline {
  margin: 0 0 8px;
  font-family: arial, helvetica, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

.subscription__advantages-bullets {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.subscription__advantages-bullet {
  background: url("/images/reflex2016/standalone/premium/icon-premium-checkbox.png") 0 3px no-repeat;
  margin-bottom: 15px;
  padding-left: 25px;
  line-height: 20px;
  list-style-type: none;
}

.subscription__types {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 50px 0 10px;
  padding: 0;
  display: flex;
}

.subscription__type {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  border: 1px solid #d1d4e4;
  flex: 0 0 48%;
  padding: 15px 15px 60px;
  list-style-type: none;
  position: relative;
}

.subscription__type .dataimg-rxpl-nejvyhodnejsi {
  color: #403014;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  display: block;
  position: absolute;
  top: -20px;
  left: -5px;
  transform: rotate(-5deg);
}

.subscription__type__voucher {
  background: #fff;
  border: 1px solid #d1d4e4;
  width: 100%;
  min-height: 110px;
  margin: 20px 0;
  padding: 14px 30px 18px 300px;
  position: relative;
  overflow: hidden;
}

.subscription__type__voucher .subscription-type__image {
  width: 50%;
  margin: 0;
  position: absolute;
  bottom: 7px;
  left: 20px;
  transform: rotate(-10deg);
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, .25);
}

.subscription__type__voucher .subscription__type-price {
  border: 0;
}

.subscription__type__voucher .subscription__type-order {
  width: 170px;
  bottom: 10px;
  left: auto;
  right: 45px;
}

.subscription__type-title {
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, helvetica, serif;
  font-size: 24px;
  line-height: 28px;
  display: block;
}

.subscription__type-price {
  color: #e00a17;
  text-align: center;
  border-bottom: 1px solid #d1d4e4;
  margin: 7px 0 10px;
  font-family: Poppins, helvetica, serif;
  font-size: 40px;
  line-height: 45px;
  display: block;
}

.subscription__type-note small {
  color: #aaa;
  font-size: 12px !important;
  line-height: 14px !important;
}

.subscription__type-order {
  color: #fff;
  text-align: center;
  background: #e00a17;
  border-radius: 2px;
  width: 88%;
  height: 34px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  line-height: 39px;
  display: block;
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.subscription__type-order:hover {
  color: #fff;
}

.subscription__type-note {
  padding: 0 10px;
  font-size: 14px !important;
  line-height: 24px !important;
}

.subscription__type-highlight {
  color: #403014;
  background: #e8c157;
  padding: 0 8px;
  font-weight: bold;
  display: inline-block;
}

.subscription__payment-options {
  justify-content: space-between;
  margin: 15px 0 0;
  display: flex;
}

#paid-zone .premium-subscription__options {
  display: block;
}

.subscription__payment-options-headline {
  margin: 0;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: inline;
}

.subscription__payment-options-bullets {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 22px;
  overflow: hidden;
}

.subscription__payment-option {
  border-right: 1px solid #d1d4e4;
  margin: 0 10px 0 0;
  padding: 0 9px 0 0;
  list-style-type: none;
  display: flex;
}

.subscription__payment-option:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

.subscription__help {
  color: #2c2f3b;
  content: "?";
  text-align: center;
  background: #d1d4e4;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.subscription__help:hover {
  cursor: help;
}

.subscription__help.active {
  background: #9aa0c4;
}

.subscription__help.active .subscription__help-text {
  display: block;
}

.dataimg-icon-premium-master-card {
  width: 26px;
}

.dataimg-icon-premium-visa {
  width: 29px;
}

.dataimg-icon-premium-gopay {
  width: 50px;
}

.dataimg-icon-premium-sms {
  width: 19px;
  position: relative;
  top: -1px;
}

.subscription__help-text {
  z-index: 1;
  text-align: left;
  color: #1e1e1e;
  background: #fcf7db;
  width: 230px;
  padding: 17px 12px;
  display: none;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.subscription__help-text:after {
  content: " ";
  border: 7.5px solid rgba(0, 0, 0, 0);
  border-top: 10px solid #fcf7db;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.subscription__help-text p {
  font-weight: normal;
  font-size: 13px !important;
  line-height: 16px !important;
}

.subscription__hide {
  text-align: center;
  margin: -5px 0 5px;
  font-size: 14px;
}

.subscription__hide + .subscription__content {
  margin-top: 15px;
}

.subscription__hide-link {
  text-decoration: underline;
  display: inline-block;
  position: relative;
}

.subscription__hide-link:hover {
  text-decoration: none;
}

.subscription__hide-link:after {
  content: "⌃";
  font-size: 14px;
  display: block;
  position: absolute;
  top: -1px;
  right: -15px;
  transform: rotate(180deg);
}

.subscription__hide-link.active:after {
  top: 4px;
  transform: rotate(0);
}

.bg-subscription {
  background-color: #f8f8f9;
}

#form-activate input.currency-input {
  margin: 10px auto;
}

.premium-x {
  background: #f8f8f9;
  margin: 0 0 30px;
}

.premium-x .subscription {
  width: 530px;
  margin: 0 auto;
}

.premium-x .subscription__headline {
  width: 95%;
}

.premium-x.recentarticles-premium {
  background: none;
  margin: 0;
}

.premium-x__archive {
  text-align: center;
}

.premium-x__archive-issue {
  width: 300px;
  margin: 0 0 20px;
}

.premium-x__archive-button {
  width: 230px;
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
}

.subscription-faq {
  background: #fff;
  border: 1px solid #d1d4e4;
  margin: 15px 0;
  padding: 15px;
}

.subscription-faq .premium-section-headline {
  text-align: left;
  font-size: 18px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.subscription-faq__questions {
  padding: 0;
}

.subscription-faq__question {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 15px;
  padding: 0 15px 15px 0;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question:hover {
  cursor: pointer;
}

.subscription-faq__question.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after {
  transform: rotate(0);
}

.subscription-faq .premium-contact__question {
  font-size: 14px;
}

.subscription-faq .premium-contact__question.open p {
  font-size: 13px;
}

.subscription-faq__answer {
  margin-top: 0;
  display: none;
}

.subscription-faq__answer p {
  margin: 15px 0 0;
}

.subscription-faq__contact {
  text-align: center;
  line-height: 27px;
}

@media (max-width: 450px) {
  #login-wrapper .main-container {
    max-width: 100%;
    margin-top: 0;
  }
}

#paid-zone {
  color: #2c2f3b;
  width: 710px;
  margin: 80px auto 0;
  font-size: 15px;
  position: relative;
}

@media (max-width: 800px) {
  #paid-zone {
    width: 95%;
    margin-top: 80px;
  }
}

@media (max-width: 450px) {
  #paid-zone {
    font-size: 14px;
  }
}

#paid-zone a {
  color: #2c2f3b;
  font-family: Poppins, Helvetica, serif;
  text-decoration: underline;
}

#paid-zone a:hover {
  text-decoration: none;
}

#paid-zone a.color-orange {
  color: #f66533;
}

#paid-zone ul {
  padding: 0;
}

#paid-zone ul.bullet-list {
  margin: 0 0 15px;
}

#paid-zone ul.bullet-list li {
  margin-bottom: 8px;
  padding-left: 17px;
  font-size: 15px;
  line-height: 1.3;
  list-style-type: none;
  position: relative;
}

#paid-zone ul.bullet-list li:last-child {
  margin-bottom: 0;
}

#paid-zone ul.bullet-list li:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
}

#paid-zone .container, #paid-zone #order-subscription {
  width: auto;
}

#paid-zone #subscription {
  margin-top: 0;
}

#paid-zone .error {
  color: #e50031;
}

#paid-zone .success {
  color: #65c41e;
}

#paid-zone .grey-highlight {
  color: #aaa;
}

#paid-zone .table {
  border-top: 1px solid #d1d4e4;
  width: 100%;
  padding: 20px 0;
}

#paid-zone .table table {
  width: 100%;
}

#paid-zone .table td {
  vertical-align: top;
  border: none;
  padding: 6px 0;
  font-family: Poppins, Helvetica, serif;
  line-height: 18px;
  position: relative;
}

@media (max-width: 450px) {
  #paid-zone .table td {
    padding: 6px 0 5px;
  }
}

#paid-zone .table td strong {
  font-family: Poppins, Helvetica, serif;
}

#paid-zone .table label {
  margin: 0;
}

@media (max-width: 450px) {
  #paid-zone .table {
    padding: 10px 0;
  }
}

#paid-zone .user-bonuses {
  border-top: 1px solid #d1d4e4;
  padding-top: 20px;
}

#paid-zone .user-bonuses + .table {
  border-top: none;
}

#paid-zone .profile-info .table + .table {
  padding-bottom: 4px;
}

#paid-zone .profile-info__header {
  text-align: center;
  padding: 20px 0 0;
}

#paid-zone .profile-info__header span {
  color: #a1a8ae;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: Poppins, Helvetica, serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

#paid-zone .profile-info__header h2 {
  color: #1f1e26;
  text-transform: uppercase;
  margin: 10px 0 0;
  font-family: Poppins, Helvetica, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
}

#paid-zone .profile-info__header p {
  color: #1f1e26;
  font-family: Poppins, Helvetica, serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
}

#paid-zone .main-info {
  text-align: left;
  border: none;
  margin: 0;
  padding: 0;
}

#paid-zone .main-info h1 {
  text-align: left;
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

@media (max-width: 450px) {
  #paid-zone .main-info h1:only-child {
    max-width: none;
  }
}

#paid-zone .main-info h1.red {
  color: red;
}

#paid-zone .main-info h1.align-center {
  text-align: left;
}

@media (max-width: 450px) {
  #paid-zone .main-info h1 {
    max-width: 150px;
  }
}

#paid-zone .main-info p {
  text-align: left;
  margin: 30px 0 13px;
  font-weight: bold;
}

#paid-zone .info-text {
  border-bottom: 1px solid #d1d4e4;
  margin-bottom: 30px;
  padding-bottom: 25px;
  font-size: 12px;
  line-height: 15px;
}

#paid-zone .prihlaseni-info {
  text-align: right;
  margin: 20px 0 10px;
  font-size: 15px;
}

@media (max-width: 450px) {
  #paid-zone .prihlaseni-info {
    float: none;
    text-align: right;
    margin: 5px 0;
    font-size: 14px;
  }
}

#paid-zone .logout {
  color: #2c2f3b;
  font-size: 15px;
  text-decoration: underline;
  position: absolute;
  top: 3px;
  right: 0;
}

#paid-zone .logout:hover {
  text-decoration: none;
}

@media (max-width: 450px) {
  #paid-zone .logout {
    font-size: 14px;
  }
}

#paid-zone .order-form .form-field {
  margin: 0;
  font-size: 14px;
}

@media (min-width: 450px) {
  #paid-zone .order-form .form-field {
    font-size: 15px;
  }
}

#paid-zone .align-center {
  text-align: center;
}

#paid-zone .backlink {
  margin: 15px 0 0;
}

#paid-zone .backlink.tos-links {
  margin-bottom: 20px;
}

#paid-zone .backlink div + .link-basic {
  margin-top: 12px;
}

#paid-zone .backlink h3 {
  font-family: Arial, sans-serif;
  font-size: 15px;
}

#paid-zone .backlink h3 a {
  color: #2c2f3b;
  font-weight: normal;
  text-decoration: underline;
}

#paid-zone .backlink h3 a:hover {
  text-decoration: none;
}

#paid-zone .main-button {
  text-align: center;
  cursor: pointer;
  background-color: #f66533;
  border: 0;
  border-radius: 2px;
  outline: none;
  max-width: 260px;
  font-weight: bold;
  transition: all .3s ease-in-out;
}

#paid-zone .main-button, #paid-zone .button-secondary {
  color: #fff;
  min-width: auto;
  height: 34px;
  margin: 0 auto;
  padding: 0 26px;
  font-family: Poppins, helvetica, serif;
  font-size: 18px;
  line-height: 38px;
  text-decoration: none;
  display: inline-block;
}

#paid-zone .button-secondary {
  color: #2c2f3b;
  background: #d1d4e4;
}

#paid-zone .button-secondary:hover {
  background: #9aa0c4;
  transition: all .3s;
}

#paid-zone .main-button + .button-secondary {
  margin-left: 12px;
}

#paid-zone .subscription-sidenote {
  background: #fcf7db;
  margin: -5px 0 0;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__type-order {
  color: #fff;
}

#paid-zone .well-content {
  margin: 0;
  padding: 0;
}

#paid-zone .subscription-notice {
  background: #eef4f7;
  width: 64%;
  margin: 25px 0 5px;
}

#paid-zone .subscription-notice p {
  padding: 10px;
}

@media (max-width: 450px) {
  #paid-zone .subscription-notice {
    width: auto;
    margin-top: 0;
  }
}

#paid-zone #activation-activate .info-text {
  border-top: 1px solid #d1d4e4;
  margin: 30px 0;
  padding: 20px 0;
}

#paid-zone #activation-activate .info-text p {
  font-size: 12px;
  line-height: 18px;
}

#paid-zone #activation-activate .info-text ol {
  margin: 0 0 0 15px;
  padding: 0;
}

#paid-zone #activation-activate .info-text ol li {
  font-size: 12px;
  line-height: 18px;
}

#paid-zone #activation-activate #subscription-login {
  margin-top: 70px;
}

#paid-zone .premium-subscription__options {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#paid-zone .premium-subscription__block {
  margin-right: 0;
}

#paid-zone .premium-subscription__button {
  color: #fff;
  text-decoration: none;
}

#paid-zone .subscription__voucher .subscription-notice {
  display: none;
}

#paid-zone .subscription__voucher .order__success-title {
  color: red;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0 40px 20px;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-title h1 {
  font-size: 24px;
  line-height: 30px;
}

#paid-zone .subscription__voucher .order__success-voucher {
  margin: 0 0 30px;
}

#paid-zone .subscription__voucher .order__success-voucher .order__success-generated {
  width: 100%;
}

#paid-zone .subscription__voucher .order__success-download {
  margin: 30px 0;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: red;
  width: 260px;
  margin: 0 auto;
  padding: 11px 15px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .dataimg-download-icon {
  float: left;
  width: 21px;
  height: 18px;
  margin-right: 5px;
}

#paid-zone .subscription__voucher .order__success-info {
  margin: 30px auto;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto ul {
  list-style: none;
}

#paid-zone .subscription__voucher .order__success-howto ul li {
  margin: 5px 0;
  font-size: 13px;
  line-height: 20px;
}

#paid-zone .subscription__voucher .order__success-contact {
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .mt-15 {
  margin-top: 15px;
}

@media (max-width: 450px) {
  #paid-zone .cancel-subscription-links a:last-of-type {
    margin-bottom: 0 !important;
  }

  #paid-zone .cancel-subscription-links a {
    margin: 0 0 20px !important;
  }
}

#paid-zone .cancel-link {
  text-align: center;
}

#paid-zone .cancel-link a {
  color: #f66533;
  font-family: Poppins, Helvetica, serif;
  font-size: 13px;
  font-weight: 500;
}

#paid-zone .add-info-text {
  text-align: left;
  border-top: 1px solid #d1d4e4;
  border-bottom: 1px solid #d1d4e4;
  margin: 45px 0;
  padding: 25px 30px;
  position: relative;
}

#paid-zone .add-info-text:before {
  content: "i";
  color: #f66533;
  text-align: center;
  background-color: #f7e1d9;
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: 28px;
  left: 0;
}

#paid-zone .important-box {
  background-image: linear-gradient(#f2eae4, #f7f7f7);
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 70px 50px 50px 80px;
}

#paid-zone .important-box h2 {
  color: #1f1e26;
  text-align: center;
  font-family: Poppins, Helvetica, serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
}

#paid-zone .important-box h2:before {
  content: "";
  background-color: #fff;
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--small.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  display: block;
  position: absolute;
  top: -40px;
  left: 50%;
}

#paid-zone .important-box p {
  color: #1f1e26;
  text-align: center;
  font-family: Poppins, Helvetica, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

#paid-zone .important-box .important-box__button {
  text-align: center;
  margin-top: 25px;
}

#paid-zone .important-box.important-box__grey {
  background-image: linear-gradient(#f0f0f0, #f7f7f7);
}

#paid-zone .important-box.important-box__grey h2:before {
  background-image: url("https://img2.cncenter.cz/images/premium/dist/svg/premium-logo-orange--grey.svg");
}

#paid-zone .bt-orange {
  color: #fff;
  cursor: pointer;
  background-color: #f66533;
  border: none;
  padding: 17px 32px;
  font-family: Poppins, Helvetica, serif;
  font-size: 15px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

@media (max-width: 550px) {
  #paid-zone .bt-orange {
    text-align: center;
    margin-bottom: 20px;
    display: block;
  }

  #paid-zone .bt-orange:last-child {
    margin-bottom: 0;
  }
}

#paid-zone .bt-orange:hover {
  opacity: .8;
}

#paid-zone .bt-orange.light-orange {
  color: #f66533;
  background-color: #f7e1d9;
}

#paid-zone .profile-info__buttons {
  justify-content: space-between;
  margin: 10px 0 100px;
  display: flex;
}

@media (max-width: 550px) {
  #paid-zone .profile-info__buttons {
    display: block;
  }
}

#subscription-login .tos-links {
  width: 600px;
  margin: 0 auto 20px;
}

#activation-login #login-wrapper {
  margin: 75px 0 35px;
}

#order-subscription {
  width: 540px;
  margin: 0 auto;
  position: relative;
}

#order-subscription .subscription-order__price-note {
  text-align: right;
  max-width: 450px;
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 450px) {
  #order-subscription .subscription-order__subscription-type {
    width: 160px;
  }
}

#order-subscription .icon-premium-master-card {
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/icon-premium-master-card.svg") 0 0 / contain no-repeat;
  width: 26px;
  height: 21px;
}

#order-subscription .icon-premium-gopay {
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/icon-premium-gopay.svg") 0 0 / contain no-repeat;
  width: 55px;
  height: 21px;
}

#order-subscription .icon-premium-visa {
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/icon-premium-visa.svg") 0 0 / contain no-repeat;
  width: 29px;
  height: 21px;
}

#order-subscription .icon-premium-sms {
  background: url("https://img2.cncenter.cz/images/premium/dist/svg/icon-premium-sms.svg") 0 0 / contain no-repeat;
  width: 19px;
  height: 21px;
}

#order-subscription .table {
  padding: 5px 0;
}

#order-subscription .table .payment td {
  padding: 8px 0;
}

#order-subscription .table .payment .table-header {
  padding-bottom: 10px;
  font-size: 18px;
}

@media (max-width: 450px) {
  #order-subscription .table .payment .table-header {
    font-size: 14px;
  }
}

#order-subscription .table .payment-row td {
  padding-bottom: 2px;
}

#order-subscription .payment-total {
  width: 100%;
  font-size: 18px;
  line-height: 21px;
}

@media (max-width: 450px) {
  #order-subscription .payment-total {
    font-size: 14px;
  }
}

#order-subscription .payment-total .final-price {
  white-space: nowrap;
  font-size: 18px;
}

#order-subscription .agreement {
  border-top: 1px solid #d1d4e4;
  padding-top: 5px;
  line-height: 20px;
}

@media (max-width: 450px) {
  #order-subscription .agreement {
    padding-top: 20px;
  }

  #order-subscription .agreement .form-field {
    margin-bottom: 8px;
  }
}

#order-subscription .agreement a {
  color: #2c2f3b;
  text-decoration: underline;
}

#order-subscription .agreement a:hover {
  text-decoration: none;
}

@media (max-width: 450px) {
  #order-subscription .agreement input[type="checkbox"] + label {
    font-size: 12px;
    line-height: 15px;
  }
}

#order-subscription .agreement input[type="checkbox"] + label:before {
  top: 2px;
}

#order-subscription .agreement .with-error a {
  color: #e00a17;
}

#order-subscription .agreement .error-message {
  color: red;
  margin-left: 29px;
  font-size: 12px;
}

#order-subscription .main-button {
  font-size: 14px;
  line-height: 34px;
}

#form-subscribe {
  text-align: center;
}

form.default-form .discount-code {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code .discount-code__label {
  margin: 0;
  display: block;
}

form.default-form .discount-code .discount-code__input {
  color: #000001;
  border: 1px solid #d1d4e4;
  border-radius: 3px;
  margin: 10px 5px 10px 0;
  transition: all .3s ease-in-out;
  display: inline-block;
}

form.default-form .discount-code .discount-code__button {
  color: #0000ca;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: 700;
  display: inline-block;
}

form.default-form .discount-code .discount-code__error {
  color: #e00a17;
}

form.default-form .price-note {
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code__price {
  color: #e00a17;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.subscription__voucher .order-form .discount-code {
  display: none;
}

#order-subscription-sms {
  width: auto;
  margin: 0 auto;
  line-height: 18px;
}

#order-subscription-sms h1 {
  margin-bottom: 25px;
}

@media (max-width: 700px) {
  #order-subscription-sms h1 {
    margin-bottom: 0;
  }
}

#order-subscription-sms .main-info {
  border-bottom: 1px solid #d1d4e4;
  margin-bottom: 30px;
  padding-bottom: 3px;
}

@media (max-width: 700px) {
  #order-subscription-sms .main-info {
    border-bottom: none;
    margin-bottom: 0;
  }
}

#order-subscription-sms #steps {
  border-bottom: 1px solid #d1d4e4;
  margin: 12px 0 27px;
  padding: 0 0 50px;
}

@media (max-width: 700px) {
  #order-subscription-sms #steps {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

#order-subscription-sms #steps .for-1:before {
  content: "1.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-2:before {
  content: "2.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-3:before {
  content: "3.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps li {
  margin: 33px 0;
  padding: 0 0 0 18px;
  list-style-type: none;
  position: relative;
}

#order-subscription-sms #steps li:first-child {
  margin-top: 0;
  padding-right: 10px;
}

#order-subscription-sms #steps li:last-child {
  margin-bottom: 0;
}

@media (max-width: 700px) {
  #order-subscription-sms #steps li {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

#order-subscription-sms #steps li form {
  margin-top: 10px;
  position: relative;
}

#order-subscription-sms #steps li form div {
  position: absolute;
}

#order-subscription-sms #steps li form span {
  color: #aaa;
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
  display: block;
  position: absolute;
  top: 14px;
  left: 10px;
}

#order-subscription-sms #steps li .error-message {
  margin-top: 10px;
  position: relative;
}

#order-subscription-sms #steps li .error-message:empty {
  display: none;
}

#order-subscription-sms #steps li .sms_input {
  float: left;
  border-color: #d1d4e4;
  width: 140px;
  height: 34px;
  margin: 5px 15px 0 0;
  padding: 0 0 0 46px;
  font-size: 15px;
  line-height: 17px;
}

@media (max-width: 700px) {
  #order-subscription-sms #steps li .sms_input {
    margin-right: 10px;
  }
}

#order-subscription-sms #steps li .main-button {
  margin: 4px 0 0;
}

#order-subscription-sms #additional-info h2 {
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

@media (max-width: 700px) {
  #order-subscription-sms #additional-info h2 {
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 18px;
  }
}

#order-subscription-sms #additional-info .subscription-sidenote {
  margin: 30px 0;
}

@media (max-width: 700px) {
  #order-subscription-sms #additional-info .subscription-sidenote {
    margin-top: 23px;
    margin-bottom: 0;
    padding-bottom: 12px;
  }
}

#order-subscription-sms #additional-info .bullet-list {
  margin-top: 13px;
  margin-bottom: 22px;
}

@media (max-width: 700px) {
  #order-subscription-sms #additional-info .bullet-list {
    margin-bottom: 20px;
  }
}

#activation-login.not-subscribed .main-info p {
  float: left;
  width: 100%;
  margin: 18px 0 30px;
}

#activation-login.not-subscribed .prihlaseni-info {
  margin: 21px 0 30px;
}

#activation-login.not-subscribed .subscription {
  clear: both;
}

.activation-steps {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  display: flex;
}

.activation-step {
  color: #2e2e2e;
  background: #ececec;
  width: calc(50% - 55px);
  padding: 9px 20px 8px;
  font-weight: 700;
  position: relative;
}

.activation-step:last-of-type {
  text-indent: 25px;
  max-width: calc(50% - 30px);
  left: -30px;
}

.activation-step:before {
  content: "";
  z-index: 1;
  border: 35px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #ececec;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.activation-step.active-step {
  color: #e00a17;
  background: #fafafa;
}

.activation-step.active-step:before {
  border-left: 30px solid #fafafa;
}

.activation-steps__step {
  font-size: 15px;
  line-height: 25px;
}

.activation-steps__info {
  font-size: 17px;
  line-height: 28px;
}

#paid-zone {
  font-size: 13px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:after {
  top: 3.75px;
  left: 3.2px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:before {
  border-width: 1px;
  border-color: #aaa;
  top: 1px;
}

#paid-zone form.default-form .form-field input[type="checkbox"] + label:before {
  border-width: 1px;
  border-color: #aaa;
}

#paid-zone .table {
  clear: both;
}

#paid-zone .subscription {
  width: 530px;
  margin: 0 auto 20px;
}

#paid-zone .tos-links {
  border-top: 1px solid #d1d4e4;
  margin: 30px 0 20px;
  padding: 30px 0 0;
}

#paid-zone .tos-links .title {
  margin-bottom: 0;
  font-family: arial, helvetica, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
}

#paid-zone .tos-links .title a {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone .tos-links .title a:hover {
  text-decoration: none;
}

#paid-zone .tos-links .link-basic {
  padding-left: 17px;
  position: relative;
}

#paid-zone .tos-links .link-basic:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

body {
  background-color: #f7f7f7;
  margin-top: 0;
}

#login-wrapper {
  margin-top: 100px;
}

#login-wrapper .main-container-login {
  box-shadow: none;
  background: none;
  align-items: center;
  margin: auto;
  display: flex;
}

@media (max-width: 650px) {
  #login-wrapper .main-container-login {
    width: auto;
  }
}

#login-wrapper .main-container-login a {
  color: #fff;
  background-color: #f66533;
  width: auto;
  margin: 0 auto;
  padding: 17px 80px;
  font-family: Poppins, Helvetica, serif;
  font-size: 15px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

@media (max-width: 500px) {
  #login-wrapper .main-container-login a {
    font-size: 16px;
  }
}

#login-wrapper .main-container-login a:hover {
  opacity: .8;
}

